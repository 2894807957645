/* eslint-disable no-param-reassign */
import { Prospect } from '@front/common/types'

interface Istate {
  customer: Prospect
}

const initialState = (): Istate => ({
  customer: {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    additionalAddress: '',
    city: '',
    company: '',
    postalCode: '',
    country: 'FR',
    phoneNumber: '',
    pointRelayAddress: {},
    promoCode: null,
    registrationCode: null,
  },
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    get: (state) => state.customer,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initialState())
    },
    update(state, newCustomer) {
      state.customer = newCustomer
    },
  },
}
