export default {
  namespaced: true,
  state: {
    modal: {},
  },
  getters: {
    get: (state) => (name) => state.modal && state.modal[name],
  },
  mutations: {
    setVisibility(state, { name, isOpen }) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$set(state.modal, name, isOpen)
    },
  },
  actions: {
    open({ commit }, name) {
      commit('setVisibility', { name, isOpen: true })
    },
    close({ commit }, name) {
      commit('setVisibility', { name, isOpen: false })
    },
  },
}
