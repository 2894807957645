<template>
  <ImageOverlay :class="$style.wrapper" :src="url">
    <template #content>
      <div
        :class="[
          $style.contentWrapper,
          {
            [$style.bottomWhiteFade]:
              defaultFadeAppearance === 'bottomWhiteFade',
            [$style.topWhiteFade]: defaultFadeAppearance === 'topWhiteFade',
            [$style.leftWhiteFade]: defaultFadeAppearance === 'leftWhiteFade',
          },
        ]"
      >
        <div :class="$style.content">
          <Typography :variant="TYPOGRAPHY_TYPES.h5">
            <slot name="date" />
          </Typography>

          <Typography :variant="TYPOGRAPHY_TYPES.h2" :class="$style.title">
            <slot name="title" />
          </Typography>

          <Typography
            :variant="TYPOGRAPHY_TYPES.h5"
            :class="$style.contentSlot"
          >
            <slot name="content" />
          </Typography>

          <slot name="button" />

          <Typography
            :variant="TYPOGRAPHY_TYPES.bodySmallRegular"
            :class="$style.cgv"
          >
            <slot name="cgv" />
          </Typography>
        </div>
      </div>
    </template>
  </ImageOverlay>
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue-demi'

import useMobile from '@front/common/hooks/useMobile'

import ImageOverlay from './ImageOverlay.vue'
import Typography from './Typography'
import TYPOGRAPHY_TYPES from './typographyTypes'

export default defineComponent({
  components: { ImageOverlay, Typography },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    desktopImageUrl: {
      type: String,
      default: '',
    },
    fadeAppearance: {
      type: String,
    },
  },
  setup(props) {
    const { imageUrl, desktopImageUrl, fadeAppearance } = toRefs(props)
    const isMobile = useMobile()

    const defaultFadeAppearance = computed(() => {
      if (!fadeAppearance.value) {
        return isMobile.value ? 'topWhiteFade' : 'leftWhiteFade'
      }

      return fadeAppearance.value
    })

    const url = computed(() => {
      if (!desktopImageUrl.value) {
        return imageUrl.value
      }

      return isMobile.value ? imageUrl.value : desktopImageUrl.value
    })

    return { TYPOGRAPHY_TYPES, url, defaultFadeAppearance }
  },
})
</script>

<style lang="scss" module>
.wrapper {
  height: 278px;
  overflow: hidden;
  border-radius: 0px;

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    border-radius: 16px;
  }
}

.contentWrapper {
  height: 100%;
  width: 100%;
  padding: $ds-spacing-mobile-small;

  &.topWhiteFade {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 40.25%,
      rgba(255, 255, 255, 0.6) 74.59%
    );
  }

  &.bottomWhiteFade {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.5) 30.25%,
      rgba(255, 255, 255, 0) 50.59%
    );
  }

  &.leftWhiteFade {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.5) 25.5%,
      rgba(255, 255, 255, 0) 74.59%
    );
  }

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    padding: 0px $ds-spacing-mobile-large;
  }
}

.content {
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100%;

  @media #{map-get($display-breakpoints, 'm-and-up')} {
    justify-content: center;
    width: 505px;
  }
}

.contentSlot {
  text-align: center;
  margin-bottom: $ds-spacing-mobile-extra-small;
  max-width: 90%;
}

.title {
  text-align: center;
}

.cgv {
  margin-top: $ds-spacing-mobile-extra-small;
  text-align: center;
}
</style>
