/* eslint-disable no-param-reassign */
import { SUCCESS } from '@front/common/constants'

const defaultDelay = 3000

let timeout = null

export default {
  namespaced: true,
  state: () => ({
    isOpen: false,
    status: null,
    message: '',
    delay: defaultDelay,
  }),
  getters: {
    getVisibility: (state) => state.isOpen,
    getStatus: (state) => state.status,
    getMessage: (state) => state.message,
    getDelay: (state) => state.delay,
  },
  mutations: {
    OPEN_ALERT(state, { status, message, delay }) {
      state.isOpen = true
      state.status = status
      state.message = message
      state.delay = delay
    },
    CLOSE_ALERT(state) {
      state.isOpen = false
      state.status = null
      state.message = ''
    },
  },
  actions: {
    open(
      { commit },
      { status = SUCCESS, message = '', delay = defaultDelay } = {},
    ) {
      clearTimeout(timeout)

      commit('OPEN_ALERT', { status, message, delay })

      timeout = setTimeout(() => commit('CLOSE_ALERT'), delay)
    },
    close({ commit }) {
      clearTimeout(timeout)

      commit('CLOSE_ALERT')
    },
  },
}
