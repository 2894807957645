export const SHOPMIUM_PROMOCODE = 'SHOPMIUM'
export const VEEPEE_PROMOCODE = 'VEEPEE'
export const SHOWROOM_PRIVE_PROMOCODE = 'SHOWROOM'

export const GODFATHER_PROMOCODE_2_EUROS_EFFECT = 'first_pet_2euros'
export const GODFATHER_PROMOCODE_5_EUROS_EFFECT = 'first_pet_5euros'
export const GODFATHER_PROMOCODE_FREE_DELIVERY_EFFECT = 'free delivery'
export const ONE_SHOT_PROMOCODE = 'one shot'
export const SKIP_PAYMENT_EFFECT = 'skip payment'
export const DISCOUNT_EFFECT = 'discount'
export const CUMULATIVE_DISCOUNT_EFFECT = 'cumulative_discount'
export const RENEWAL_DISCOUNT_EFFECT = 'renewal_discount'
export const FREE_KIBBLES_EFFECT = 'free kibbles'

export const GIFT_CARD = 'giftCard'
export const OPTION_EFFECT_TYPE = 'option'

export const EFFECT_FIRST_PET_2_EUROS = 'firstPet2Euros'
export const EFFECT_FIRST_PET_5_EUROS = 'firstPet5Euros'
export const EFFECT_FIRST_PET_FREE = 'firstPetFree'
export const EFFECT_SKIP_PAYMENT = 'skipPayment'
