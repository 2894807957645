<template>
  <div :class="$style.wrapper">
    <svg
      v-for="r in ratesLength"
      :key="r"
      :class="$style.rate"
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.95471 8.03245L8.2986 7.43472L9.2779 10.4719L5.95471 8.03245ZM11.3489 4.10678H7.22298L5.95471 0.197266L4.68644 4.10678H0.560547L3.89979 6.53004L2.63152 10.4396L5.97077 8.0163L8.02568 6.53004L11.3489 4.10678Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    rate: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ratesLength() {
      return new Array(this.rate)
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
}

.rate {
  display: flex;
  margin-right: 3px;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  background: #00b67a;
}
</style>
