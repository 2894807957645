<template>
  <Modal name="wetfood-removal-modal">
    <div :class="$style.wrapper">
      <img
        src="@front/common/assets/dog_bowl_with_background.svg"
        width="50"
        height="50"
      />

      <Typography :variant="TYPOGRAPHY_TYPES.h5" tag="h5" :class="$style.title">
        {{ $i18n('Voulez-vous supprimer la terrine du menu ?') }}
      </Typography>

      <Typography tag="p">
        {{ $i18n('Vous pourrez à tout moment en rajouter par la suite.') }}
      </Typography>

      <Divider />

      <div :class="$style.buttons">
        <Button appearance="largeSecondary" :class="$style.button">
          {{ $i18n('Annuler') }}
        </Button>

        <Button :class="$style.button" @click="confirm">
          {{ $i18n('Supprimer') }}
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue-demi'

import { Button, Divider, Typography, TYPOGRAPHY_TYPES, Modal } from '@front/ui'

export default defineComponent({
  components: { Modal, Typography, Divider, Button },
  computed: {
    TYPOGRAPHY_TYPES: () => TYPOGRAPHY_TYPES,
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
  },
})
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
}

.title {
  margin: $ds-spacing-mobile-small 0px;
}

.repartitionBlock {
  margin: $ds-spacing-mobile-small auto;
}

.buttons {
  margin-top: $ds-spacing-mobile-small;
  display: flex;
  justify-content: center;
  gap: $ds-spacing-mobile-small;
}

.button {
  min-width: auto;
  width: 100%;
}
</style>
