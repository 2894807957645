import { RouteConfig } from 'vue-router'

import profileBuilderRoutes from '../pages/ProfileBuilder/profileBuilder.routes'

import {
  SHOWCASE_ROUTE_DELIVERY,
  SHOWCASE_ROUTE_PROFILE_BUILDER,
  SHOWCASE_ROUTE_PROFILE_BUILDER_ERROR,
  SHOWCASE_ROUTE_PRODUCT,
  SHOWCASE_ROUTE_PRODUCTS,
  SHOWCASE_ROUTE_QUOTATION,
  SHOWCASE_ROUTE_HOMEPAGE,
  SHOWCASE_ROUTE_NEEDS,
  SHOWCASE_ROUTE_MENU,
  SHOWCASE_ROUTE_DISCOVERY_OFFER,
  SHOWCASE_ROUTE_EDITION_MENU,
  SHOWCASE_ROUTE_SUMMARY,
  SHOWCASE_ROUTE_RESUBSCRIBE_SUMMARY,
} from './routes-names'

function importDefault(promise: Promise<any>): Promise<any> {
  return promise.then((m) => m.default || m)
}

const routes: RouteConfig[] = [
  {
    path: '/delivery/',
    name: SHOWCASE_ROUTE_DELIVERY,
    component: () => importDefault(import('@showcase/pages/delivery')),
    meta: {
      transition: 'fade',
      i18nPages: [
        'global',
        'recipes',
        'upsell_products',
        'profile_builder_products',
        'profile-builder-delivery',
        'summary',
        'snoopy_form_validation',
      ],
    },
  },
  {
    path: '/error/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_ERROR,
    component: () => importDefault(import('@showcase/pages/error.vue')),
    meta: {
      i18nPages: ['profile-builder-errors'],
    },
  },
  {
    path: '/quotation/',
    name: SHOWCASE_ROUTE_QUOTATION,
    component: () => importDefault(import('@showcase/pages/quotation.vue')),
    meta: {
      transition: 'fade',
      i18nPages: ['recipes', 'upsell_products', 'quotation', 'global'],
    },
  },
  {
    path: '/',
    name: SHOWCASE_ROUTE_HOMEPAGE,
    component: () => importDefault(import('@showcase/pages/Home')),
  },
  {
    path: '/profile-builder/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER,
    component: () => importDefault(import('@showcase/pages/ProfileBuilder')),
    children: profileBuilderRoutes,
  },
  {
    path: '/products/',
    name: SHOWCASE_ROUTE_PRODUCTS,
    component: () => importDefault(import('@showcase/pages/Products')),
    meta: {
      i18nPages: [
        'summary',
        'recipes',
        'profile-builder-delivery',
        'upsell_products',
        'profile_builder_products',
        'global',
      ],
    },
  },
  {
    path: '/product/:id',
    name: SHOWCASE_ROUTE_PRODUCT,
    component: () => importDefault(import('@showcase/pages/Product')),
    meta: {
      i18nPages: ['upsell_products'],
    },
  },
  {
    path: '/needs/',
    name: SHOWCASE_ROUTE_NEEDS,
    component: () => importDefault(import('@showcase/pages/Needs')),
    meta: {
      i18nPages: ['breed_cat', 'breed', 'global'],
    },
  },
  {
    path: '/menu/',
    name: SHOWCASE_ROUTE_MENU,
    component: () => importDefault(import('@showcase/pages/Menu')),
    meta: {
      i18nPages: [
        'breed_cat',
        'breed',
        'global',
        'profile-builder-recipe',
        'items',
      ],
    },
  },
  {
    path: '/edition-menu/',
    name: SHOWCASE_ROUTE_EDITION_MENU,
    component: () => importDefault(import('@showcase/pages/EditionMenu')),
    props: ({ query }) => {
      return { customerId: Number(query.customerId) }
    },
    meta: {
      i18nPages: [
        'breed_cat',
        'breed',
        'global',
        'profile-builder-recipe',
        'items',
      ],
    },
  },
  {
    path: '/summary/',
    name: SHOWCASE_ROUTE_SUMMARY,
    component: () => importDefault(import('@showcase/pages/Summary')),
    props: ({ query }) => {
      return { customerId: Number(query.customerId) }
    },
    meta: {
      i18nPages: [
        'breed_cat',
        'breed',
        'global',
        'profile-builder-recipe',
        'items',
      ],
    },
  },
  {
    path: '/resubscribe-summary/',
    name: SHOWCASE_ROUTE_RESUBSCRIBE_SUMMARY,
    component: () =>
      importDefault(import('@showcase/pages/ResubscribeSummary')),
    props: ({ query }) => {
      return { customerId: Number(query.customerId) }
    },
    meta: {
      i18nPages: [
        'breed_cat',
        'breed',
        'global',
        'profile-builder-recipe',
        'items',
      ],
    },
  },
  {
    path: '/discovery-offer/',
    name: SHOWCASE_ROUTE_DISCOVERY_OFFER,
    component: () => importDefault(import('@showcase/pages/DiscoveryOffer')),
    meta: {
      i18nPages: ['global', 'summary', 'profile-builder-delivery'],
    },
  },
]

export default routes
