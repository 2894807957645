import modal from '@front/common/store/modal'
import alert from '@front/ui/Alert/Alert.store'
import Vuex from 'vuex'

import cart from './cart'
import customer from './customer'
import customerActivePets from './customerActivePets'
import discounts from './discounts'
import payment from './payment'
import pets from './pets'
import products from './products'
import profileBuilder from './profileBuilder'
import renewalServices from './renewalServices'
import route from './route'
import subscription from './subscription'
import subscriptions from './subscriptions'
import trial from './trial'

const createStore = () => {
  return new Vuex.Store({
    modules: {
      modal,
      cart,
      customer,
      alert,
      customerActivePets,
      discounts,
      payment,
      products,
      pets,
      profileBuilder,
      renewalServices,
      route,
      subscription,
      subscriptions,
      trial,
    },
  })
}

export default createStore
