var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasDiscount)?_c('div',{class:_vm.$style.wrapper},[_c('TagIcon',{class:_vm.$style.tagIcon,attrs:{"size":"1x"}}),_c('div',{class:_vm.$style.amountWrapper},[(_vm.hasRenewalDiscount)?_c('Popper',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('Button',{class:_vm.$style.link,attrs:{"appearance":"link"}},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n            "+_vm._s(_vm.$i18n('{discountAmount} de réduction', {
                discountAmount: _vm.discountAmount,
              }))+"\n          ")])],1)]},proxy:true},{key:"content",fn:function(){return [_c('ul',{class:_vm.$style.shippings},[_c('li',{class:_vm.$style.shippingWrapper},[_c('Typography',{class:_vm.$style.discountText,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallBold}},[_vm._v("\n              "+_vm._s(_vm.$i18n('{discount} de réduction', {
                  discount: _vm.formatDiscountValue(_vm.registrationDiscount),
                }))+"\n            ")]),_c('BoxIcon',{class:_vm.$style.boxIcon,attrs:{"size":"2x"}}),_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallRegular}},[_vm._v("\n              "+_vm._s(_vm.$i18n('Livraison {number}', { number: 1 }))+"\n            ")])],1),_vm._l((_vm.renewalDiscounts),function(discount,index){return _c('li',{key:index,class:_vm.$style.shippingWrapper},[_c('Typography',{class:_vm.$style.discountText,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallBold}},[_vm._v("\n              "+_vm._s(_vm.$i18n('{discount} de réduction', {
                  discount: _vm.formatDiscountValue(discount),
                }))+"\n            ")]),_c('BoxIcon',{class:_vm.$style.boxIcon,attrs:{"size":"2x"}}),_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallRegular}},[_vm._v("\n              "+_vm._s(_vm.$i18n('Livraison {number}', { number: index + 2 }))+"\n            ")])],1)})],2)]},proxy:true}],null,false,2584568123)}):_vm._e(),(!_vm.hasRenewalDiscount)?_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n      "+_vm._s(_vm.$i18n('{discountAmount} de réduction', {
          discountAmount: _vm.discountAmount,
        }))+"\n    ")]):_vm._e(),_c('div',[(_vm.hasRenewalDiscount)?_c('Typography',{class:_vm.$style.text},[_vm._v("\n        "+_vm._s(_vm.$i18n('au total'))+"\n      ")]):_vm._e(),(_vm.promoCode)?_c('Typography',{class:_vm.$style.text},[_vm._v("\n        "+_vm._s(_vm.$i18n('avec le code'))+"\n      ")]):_vm._e()],1)],1),(_vm.promoCode)?_c('Typography',{class:_vm.$style.code,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n    #"+_vm._s(_vm.promoCode.code)+"\n  ")]):_vm._e(),(!_vm.promoCode)?_c('Typography',{class:_vm.$style.code,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n    "+_vm._s(_vm.$i18n('Offre de bienvenue'))+"\n  ")]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }