import { createIntl, createIntlCache } from '@formatjs/intl'
import * as prismic from '@prismicio/client-next'

import { formatStringWithVariables } from './utils'

const fetchedPages = {}
let translations = {}
let language = 'fr-FR'
let withWarning = true
let cachedClientApi = null

const cache = createIntlCache()

export const intl = createIntl({
  locale: language,
  defaultLocale: language,
  cache,
  onError: () => ({}),
})

export const translate = (args, variables) => {
  const key = typeof args === 'string' ? args : args.value
  const currentKey = translations[key] || args.defaultMessage || key
  const descriptor = {
    id: key,
    description: '',
    defaultMessage: currentKey,
  }

  if (!translations[key] && withWarning) {
    // eslint-disable-next-line no-console
    console.warn(`key: "${args.value}" was not fetched.`)
  }

  if (Array.isArray(currentKey)) {
    return currentKey
  }

  return currentKey.includes('$')
    ? formatStringWithVariables(currentKey, variables) // @TODO: Remove this part when all translations have been migrated
    : intl.formatMessage(descriptor, variables)
}

export async function fetchTranslations(i18nPages = []) {
  try {
    if (!cachedClientApi) {
      const endpoint = prismic.getEndpoint('japhy')

      let fetchedSource = null

      if (typeof window === 'undefined') {
        fetchedSource = await import('node-fetch')
      } else {
        fetchedSource = window.fetch
      }

      cachedClientApi = prismic.createClient(endpoint, { fetch: fetchedSource })
    }

    const services = i18nPages.reduce(
      (acc, prismicPage) =>
        fetchedPages[prismicPage]
          ? acc
          : [...acc, cachedClientApi.getSingle(prismicPage)],
      [],
    )
    const prismicData = await Promise.all(services)

    prismicData.forEach(({ type, data, lang }) => {
      language = lang
      fetchedPages[type] = true
      translations = { ...translations, ...data }
    })

    return translations
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export default {
  install(
    Vue,
    { noClientMode = false, withWarning: warning = true, router } = {},
  ) {
    withWarning = warning

    router.beforeEach(async (to, from, next) => {
      // Used when new applications does not have prismic settings yet.
      const pages =
        typeof to.meta.i18nPages === 'function'
          ? to.meta.i18nPages(to)
          : to.meta.i18nPages || []

      if (!noClientMode) {
        await fetchTranslations(pages || [])
      }

      if (Vue.prototype) {
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$i18n = translate
      }

      next()
    })
  },
}
