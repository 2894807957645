<template>
  <label
    :class="[
      $style.wrapper,
      {
        [$style.primary]: variant === 'primary',
        [$style.discount]: variant === 'discount',
      },
    ]"
  >
    <Typography :variant="TYPOGRAPHY_TYPES.bodySmallBold">
      <slot />
    </Typography>
  </label>
</template>

<script>
import { defineComponent } from 'vue-demi'

import { Typography, TYPOGRAPHY_TYPES } from '.'

export default defineComponent({
  components: { Typography },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    TYPOGRAPHY_TYPES: () => TYPOGRAPHY_TYPES,
  },
})
</script>

<style lang="scss" module>
.wrapper {
  background: grey;
  padding: 5px $ds-spacing-mobile-extra-small;
  border-radius: 5px;
  display: inline-flex;

  &.primary {
    background: $ds-color-black-borders;
  }

  &.discount {
    background: $ds-color-yellow-dark;
  }
}
</style>
