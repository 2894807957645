import { ref, readonly } from 'vue-demi'

export default function useState(initialState) {
  const state = ref(initialState)

  function setState(newState) {
    state.value = newState
  }

  return [readonly(state), setState]
}
