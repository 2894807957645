var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.cardsWrapper},_vm._l((_vm.items),function(ref){
var id = ref.id;
var imageUrls = ref.imageUrls;
var translationKeys = ref.translationKeys;
var recipe = ref.recipe;
var type = ref.type;
var isNew = ref.isNew;
return _c('div',{key:id},[_c('ProductInput',{class:_vm.$style.product,attrs:{"value":id,"name":_vm.name,"type":_vm.isKibblesItem(type) ? 'radio' : 'checkbox',"checked":_vm.isItemSelected(id),"title":recipe.marketLabel},on:{"change":function (value) { return _vm.$emit('selected', value); }},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('Packshot',{class:_vm.$style.cardImage,attrs:{"src":imageUrls.thumbnail,"text":_vm.isKibblesItem(type) ? _vm.pet.name : ''}},[(isNew)?_c('Badge',[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallRegular}},[_vm._v("\n              "+_vm._s(_vm.$i18n('Nouveauté'))+"\n            ")])],1):_vm._e()],1)]},proxy:true},{key:"content",fn:function(){return [_c('Button',{class:_vm.$style.link,attrs:{"appearance":"link","data-market":"recipe-product-more-details"},on:{"click":function($event){_vm.openModal(_vm.getModalName(id))}}},[_vm._v("\n          "+_vm._s(_vm.$i18n('En savoir plus'))+"\n        ")])]},proxy:true}],null,true)}),_c('Modal',{attrs:{"name":_vm.getModalName(id)}},[_c('ItemDetails',{attrs:{"pet":_vm.pet,"translation-keys":translationKeys,"recipe":recipe,"image-urls":imageUrls,"is-kibbles":_vm.isKibblesItem(type)}})],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }