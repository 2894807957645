/* eslint-disable no-param-reassign */
export default {
  state: () => ({
    route: {},
  }),
  mutations: {
    setRoute(state, route) {
      state.route = route
    },
  },
}
