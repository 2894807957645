export default (delay = 1000) => {
  let timeout = null

  function resetTimeout() {
    return clearTimeout(timeout)
  }

  function timeoutDone() {
    return new Promise((resolve) => {
      timeout = setTimeout(() => {
        return resolve(undefined)
      }, delay)
    })
  }

  return [timeoutDone, resetTimeout]
}
