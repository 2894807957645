var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('img',{class:_vm.$style.diameterImage,attrs:{"src":require("@front/common/assets/kibble-image.png"),"height":"96"}}),_c('Typography',{class:_vm.$style.diameter,attrs:{"tag":"div","variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n    "+_vm._s(_vm.intl.formatNumber(_vm.kibblesDiameter, {
        style: 'unit',
        unit: 'millimeter',
        unitDisplay: 'narrow',
        notation: 'compact',
      }))+"\n  ")]),(_vm.pet)?_c('Typography',{class:_vm.$style.kibblesDescription,attrs:{"tag":"p"}},[_vm._v("\n    "+_vm._s(_vm.$i18n(
        "Des croquettes de {\n            kibblesSize, select, small {petite} medium {moyenne} other {grande}\n          } taille pour une mastication adaptée à votre {petType}",
        {
          petType: _vm.petType,
          kibblesSize: _vm.kibblesSize,
        }
      ))+"\n  ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }