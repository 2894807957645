/* eslint-disable no-param-reassign */
import { getCurrentDiscounts } from '@front/common/endpoints'
import { DiscountCollection } from '@front/common/types'

interface Istate {
  discounts: Partial<DiscountCollection>
}

const initialState = (): Istate => ({
  discounts: {},
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    get: (state) => state.discounts,
  },
  mutations: {
    set(state, payload: DiscountCollection) {
      state.discounts = payload
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const discounts = await getCurrentDiscounts()

        commit('set', discounts)
      } catch (error) {
        console.error('Failed to fetch discounts:', error)
      }
    },
  },
}
