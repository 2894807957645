/* eslint-disable no-param-reassign */
export default {
  namespaced: true,
  state: () => ({
    subscription: {},
  }),
  getters: {
    get: (state) => state.subscription,
  },
  mutations: {
    set(state, payload) {
      state.subscription = payload
    },
  },
}
