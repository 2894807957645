import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  return createPersistedState({
    storage: window.localStorage,
    paths: [
      'pets.drafts',
      'pets.pets',
      'profileBuilder.id',
      'profileBuilder.visitedStepsName',
      'profileBuilder.petCountFlow',
      'profileBuilder.petIndex',
    ],
  })(store)
}
