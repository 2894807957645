import { datadogRum } from '@datadog/browser-rum'
import { sentryConfig, datadogConfig } from '@front/common/tracking'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'

Vue.config.errorHandler = function (err) {
  console.error(err)
}

Sentry.init({
  Vue,
  ...sentryConfig,
  release: process.env.RELEASE_NAME_SHOWCASE,
  dsn: 'https://8e2e59a6b5804efba4613334e4c9eb7f@o576357.ingest.sentry.io/5769469',
})

datadogRum.init(datadogConfig)
