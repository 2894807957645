<template>
  <div :class="$style.wrapper">
    <RepartitionImage
      :repartition="currentRatio"
      height="115"
      width="115"
      kibbles-url="https://japhy-assets.twic.pics/download?id=1GrLZv9EWO2wpflmacL_AkaWt5KjXIhTe&twic=v1/resize=200"
      wet-food-url="https://japhy-assets.twic.pics/download?id=1GeJXOj7ZYeS5AFV7lJ_MQlbxp6Se0CKU&twic=v1/resize=200"
    />

    <div :class="$style.contentBlock">
      <ControlledInputNumber
        :value="currentRatio"
        :allowed-values="allowedWetfoodRatios"
        @input="(value) => $emit('updateRepartition', value)"
      >
        <template #default="{ value, handleInput }">
          <TextInput
            :value="value"
            :class="$style.textInput"
            @input="handleInput"
          >
            <template #rightIcon>%</template>
          </TextInput>
        </template>
      </ControlledInputNumber>

      <Badge
        v-if="isRecommandedRatio"
        appearance="secondary"
        :class="$style.badge"
      >
        {{ $i18n('Recommandé') }}
      </Badge>
    </div>

    <Typography :variant="TYPOGRAPHY_TYPES.handwritten" :class="$style.kibbles">
      {{ $i18n('Croquettes') }}
    </Typography>

    <img src="@front/common/assets/arrow.svg" :class="$style.wetfoodArrow" />

    <img src="@front/common/assets/arrow.svg" :class="$style.kibblesArrow" />

    <Typography :variant="TYPOGRAPHY_TYPES.handwritten" :class="$style.wetfood">
      {{ $i18n('Terrine') }}
    </Typography>
  </div>
</template>

<script>
import { defineComponent } from 'vue-demi'

import { WETFOOD_RATIO, WETFOOD_RATIO_VALUES } from '@front/common/constants'
import {
  Badge,
  ControlledInputNumber,
  RepartitionImage,
  TextInput,
  Typography,
  TYPOGRAPHY_TYPES,
} from '@front/ui'

export default defineComponent({
  components: {
    RepartitionImage,
    Typography,
    ControlledInputNumber,
    Badge,
    TextInput,
  },
  props: {
    wetfoodRatio: {
      type: String,
      required: true,
    },
  },
  computed: {
    TYPOGRAPHY_TYPES: () => TYPOGRAPHY_TYPES,
    isRecommandedRatio() {
      return WETFOOD_RATIO.low === this.wetfoodRatio
    },
    currentRatio() {
      return WETFOOD_RATIO_VALUES[this.wetfoodRatio]
    },
    allowedWetfoodRatios() {
      return Object.values(WETFOOD_RATIO_VALUES)
    },
  },
})
</script>

<style lang="scss" module>
.wrapper {
  display: inline-flex;
  position: relative;
  align-items: flex-start;
  padding: 20px 0px;
  padding-left: 50px;
}

.select {
  margin-top: $ds-spacing-mobile-small;
}

.kibbles.kibbles {
  position: absolute;
  left: 0px;
  bottom: 0px;
  font-size: 24px;
}

.kibblesArrow {
  position: absolute;
  left: 20px;
  bottom: 30px;
  width: 25px;
  transform: rotate(150deg);
}

.wetfood.wetfood {
  left: 220px;
  top: 0px;
  position: absolute;
  font-size: 24px;
}

.wetfoodArrow {
  position: absolute;
  left: 160px;
  top: 10px;
  width: 25px;
}

.contentBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 29px;
  margin-left: $ds-spacing-mobile-small;
}

.badge {
  margin-top: $ds-spacing-mobile-small;
}

.textInput {
  min-width: 73px;
  pointer-events: none;
}

.textInput input {
  text-align: center;
  font-weight: bold;
}
</style>
