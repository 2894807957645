import { postPromoCode } from '@front/common/endpoints'

export default async ({ route, store }) => {
  const customer = store.getters['customer/get']
  const promoCode = route.query.code_promo

  if (!promoCode) {
    return
  }

  const pets = store.getters['pets/get']

  try {
    const data = await postPromoCode(pets, promoCode)

    store.dispatch('cart/setPromoCode', data)
    store.commit('customer/update', {
      ...customer,
      registrationCode: data,
    })
  } catch (error) {}
}
