<template>
  <Icon :name="iconName" />
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue-demi'

import Icon from './Icon'

export default defineComponent({
  components: { Icon },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isVisible } = toRefs(props)

    const iconName = computed(() => {
      return isVisible.value ? 'nav-arrow-up' : 'nav-arrow-down'
    })

    return { iconName }
  },
})
</script>
