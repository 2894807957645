<template>
  <div :class="$style.content" :style="{ '--background-color': opacity }">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue-demi'

export default defineComponent({
  props: {
    opacity: {
      type: Number,
      default: 0.5,
    },
  },
})
</script>

<style module lang="scss">
.content {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.content::before {
  z-index: 1;
  border-radius: inherit;
  position: absolute;
  display: block;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, var(--background-color));
  content: '';
}
</style>
