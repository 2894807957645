import { virtualPageViewDataLayerEvent } from '@front/common/data-layer-events'

export default ({ app, store }) => {
  // Every time the route changes (fired on initialization too)
  app.router.afterEach((to) => {
    const { protocol, hostname, pathname, search } = document.location || {}
    const originalLocation = `${protocol}//${hostname}${pathname}${search}`

    virtualPageViewDataLayerEvent(originalLocation, to.name)
    store.commit('setRoute', to)
  })
}
