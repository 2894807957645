import { RouteConfig } from 'vue-router'

import {
  SHOWCASE_ROUTE_PROFILE_BUILDER,
  SHOWCASE_ROUTE_PROFILE_BUILDER_ACTIVITY,
  SHOWCASE_ROUTE_PROFILE_BUILDER_AGE,
  SHOWCASE_ROUTE_PROFILE_BUILDER_BREED,
  SHOWCASE_ROUTE_PROFILE_BUILDER_COUNT,
  SHOWCASE_ROUTE_PROFILE_BUILDER_FATNESS,
  SHOWCASE_ROUTE_PROFILE_BUILDER_GENDER,
  SHOWCASE_ROUTE_PROFILE_BUILDER_LOADER,
  SHOWCASE_ROUTE_PROFILE_BUILDER_NAME,
  SHOWCASE_ROUTE_PROFILE_BUILDER_PARENT,
  SHOWCASE_ROUTE_PROFILE_BUILDER_REPARTITION,
  SHOWCASE_ROUTE_PROFILE_BUILDER_SPAYED,
  SHOWCASE_ROUTE_PROFILE_BUILDER_SPECIES,
  SHOWCASE_ROUTE_PROFILE_BUILDER_VALIDATE,
  SHOWCASE_ROUTE_PROFILE_BUILDER_WEIGHT,
} from '../../core/routes-names'

function importDefault(promise: Promise<any>): Promise<any> {
  return promise.then((m) => m.default || m)
}

const routes: RouteConfig[] = [
  {
    path: '/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER,
  },
  {
    path: 'loader/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_LOADER,
    component: () => importDefault(import('./pages/Loader')),
    meta: {
      i18nPages: ['profile-builder-index', 'global'],
    },
  },
  {
    path: 'count/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_COUNT,
    component: () => importDefault(import('./pages/Count')),
    meta: {
      i18nPages: ['profile-builder-index', 'global'],
    },
  },
  {
    path: 'validate/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_VALIDATE,
    component: () => importDefault(import('./pages/Validate')),
    meta: {
      i18nPages: ['profile-builder-validate', 'global'],
    },
  },
  {
    path: 'activity/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_ACTIVITY,
    component: () => importDefault(import('./pages/Activity')),
    meta: {
      i18nPages: ['profile-builder-activity', 'global'],
    },
  },
  {
    path: 'age/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_AGE,
    component: () => importDefault(import('./pages/Age')),
    meta: {
      i18nPages: ['profile-builder-age', 'global'],
    },
  },
  {
    path: 'breed/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_BREED,
    component: () => importDefault(import('./pages/Breed')),
    meta: {
      i18nPages: [
        'breed_cat',
        'breed',
        'profile-builder-breed-and-cross',
        'global',
      ],
    },
  },
  {
    path: 'fatness/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_FATNESS,
    component: () => importDefault(import('./pages/Fatness')),
    meta: {
      i18nPages: ['profile-builder-fatness', 'global'],
    },
  },
  {
    path: 'name/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_NAME,
    component: () => importDefault(import('./pages/Name')),
    meta: {
      i18nPages: ['profile-builder-name', 'global', 'snoopy_form_validation'],
    },
  },
  {
    path: 'repartition/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_REPARTITION,
    component: () => importDefault(import('./pages/Repartition')),
    meta: {
      i18nPages: ['profile-builder-repartition', 'global'],
    },
  },
  {
    path: 'gender/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_GENDER,
    component: () => importDefault(import('./pages/Gender')),
    meta: {
      i18nPages: ['profile-builder-sex', 'global'],
    },
  },
  {
    path: 'spayed/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_SPAYED,
    component: () => importDefault(import('./pages/Spayed')),
    meta: {
      i18nPages: ['profile-builder-spayed', 'global'],
    },
  },
  {
    path: 'species/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_SPECIES,
    component: () => importDefault(import('./pages/Species')),
    meta: {
      i18nPages: ['profile-builder-species', 'global'],
    },
  },
  {
    path: 'weight/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_WEIGHT,
    component: () => importDefault(import('./pages/Weight')),
    meta: {
      i18nPages: ['profile-builder-weight', 'global'],
    },
  },
  {
    path: 'parent/',
    name: SHOWCASE_ROUTE_PROFILE_BUILDER_PARENT,
    component: () => importDefault(import('./pages/Parent')),
    meta: {
      i18nPages: ['global', 'snoopy_form_validation'],
    },
  },
]

export default routes
