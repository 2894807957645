import { FlowType } from '@front/common/types'

import classicSteps from './classic'
import creationSteps from './creation.steps'
import editingSteps from './editing.steps'

export default {
  [FlowType.classic]: classicSteps,
  [FlowType.creation]: creationSteps,
  [FlowType.update]: editingSteps,
}
