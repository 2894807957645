import { httpClientIntegration } from '@sentry/integrations'
import { Options, TracingOptions } from '@sentry/vue/types/types'

export const sentryConfig = {
  environment: process.env.APP_ENV,
  logErrors: process.env.APP_ENV !== 'production',
  integrations: [httpClientIntegration()],
  tracingOptions: {
    trackComponents: true,
  },
  sendDefaultPii: true,
  tracesSampleRate: 0.25,
  sampleRate: 1,
} as Partial<
  Omit<Options, 'tracingOptions'> & {
    tracingOptions: Partial<TracingOptions>
  }
>

export const datadogConfig = {
  applicationId: 'f6c0cbeb-887a-4433-86d6-4eef6ccaf45a',
  clientToken: 'pub6f637b17eba2c044a015d2cf5703a72b',
  site: 'datadoghq.eu',
  service: 'front',
  env: process.env.APP_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
}
