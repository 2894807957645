import { CAT, DOG } from '@showcase/helpers/constants'

import {
  countStep,
  speciesStep,
  nameStep,
  genderStep,
  spayedStep,
  breedStep,
  ageStep,
  activityStep,
  fatnessStep,
  weightStep,
  repartitionStep,
  validateStep,
  parentStep,
  loaderStep,
} from '../steps'

/**
 * @description adds a `/validate` page in the infinite pet case
 */
export default {
  [DOG]: [
    countStep,
    speciesStep,
    nameStep,
    genderStep,
    spayedStep,
    breedStep,
    ageStep,
    activityStep,
    fatnessStep,
    weightStep,
    repartitionStep,
    validateStep,
    loaderStep,
    parentStep,
  ],
  [CAT]: [
    countStep,
    speciesStep,
    nameStep,
    genderStep,
    spayedStep,
    breedStep,
    ageStep,
    activityStep,
    fatnessStep,
    weightStep,
    repartitionStep,
    validateStep,
    loaderStep,
    parentStep,
  ],
}
