var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"controlledInputNumber",class:_vm.$style.wrapper},[_c('Button',{class:[
      _vm.$style.button,
      _vm.$style.decrement,
      ( _obj = {}, _obj[_vm.$style.isDisabled] = _vm.isDecrementDisabled, _obj ) ],on:{"click":function($event){$event.stopPropagation();return _vm.wrappingHandler(_vm.decrement)}}},[_c('Icon',{class:[_vm.$style.sign, _vm.$style.centeredContent],attrs:{"name":"minus"}})],1),_c('div',{class:_vm.$style.inputWrapper},[_vm._t("default",function(){return [_c('TextInput',_vm._g(_vm._b({class:[_vm.$style.input, _vm.$style.centeredContent],attrs:{"disabled":_vm.disabled,"value":_vm.formattedValue},on:{"click":function($event){$event.stopPropagation();}}},'TextInput',_vm.$attrs,false),_vm.listeners))]},{"disabled":_vm.disabled,"handleInput":_vm.handleInput,"value":_vm.formattedValue})],2),_c('Button',{class:[
      _vm.$style.button,
      _vm.$style.increment,
      ( _obj$1 = {}, _obj$1[_vm.$style.isDisabled] = _vm.isIncrementDisabled, _obj$1 ) ],on:{"click":function($event){$event.stopPropagation();return _vm.wrappingHandler(_vm.increment)}}},[_c('Icon',{class:[_vm.$style.sign, _vm.$style.centeredContent],attrs:{"name":"plus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }