import { CAT, DOG } from '@showcase/helpers/constants'

import {
  nameStep,
  genderStep,
  spayedStep,
  breedStep,
  ageStep,
  activityStep,
  fatnessStep,
  weightStep,
  repartitionStep,
  loaderStep,
} from './steps'

/**
 * @description it starts directly on the `name` page, no need to define species, count
 */
export default {
  [DOG]: [
    nameStep,
    genderStep,
    spayedStep,
    breedStep,
    ageStep,
    activityStep,
    fatnessStep,
    weightStep,
    repartitionStep,
    loaderStep,
  ],
  [CAT]: [
    nameStep,
    genderStep,
    spayedStep,
    breedStep,
    ageStep,
    activityStep,
    fatnessStep,
    weightStep,
    repartitionStep,
    loaderStep,
  ],
}
