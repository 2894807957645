/* eslint-disable no-param-reassign */
import { DOG } from '@front/common/constants'
import { FlowType, PetCountFlowType } from '@front/common/types'
import { uuid } from '@front/common/utils'

import flows from '@showcase/pages/ProfileBuilder/steps/flows'

const initialState = () => ({
  id: uuid(),
  currentEdition: {
    errorMessages: {
      phone: '',
      email: '',
    },
    transactionId: null,
    transactionHt: null,
    transactionTax: null,
  },
  flow: FlowType.classic,
  petCountFlow: PetCountFlowType.single,
  petIndex: 0,
  utm: null,
  visitedStepsName: [],
  isCompleted: false,
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getSteps: (state, getters, _rootState, rootGetters) => {
      const currentDraft = rootGetters['pets/getCurrentDraft']
      const species = currentDraft.species || DOG

      if (getters.getFlow === FlowType.classic) {
        return flows[getters.getFlow][getters.getPetCountFlow][species]
      }
      return flows[getters.getFlow][species]
    },
    getUtm: (state) => state.utm,
    getId: (state) => state.id,
    getPetIndex: (state) => state.petIndex,
    getPetCountFlow: (state) => state.petCountFlow,
    getFlow: (state) => state.flow,
    getDisplayedSteps: (state, getters) => {
      return getters.getSteps.filter(
        ({ isDisplayed }) => !isDisplayed || isDisplayed(),
      )
    },
    isCompleted: (state) => state.isCompleted,
    getCompletedSteps: (state, getters, _rootState, rootGetters) => {
      const currentDraft = rootGetters['pets/getCurrentDraft']
      const visitedStepsName = state.visitedStepsName[state.petIndex] || []

      return getters.getDisplayedSteps.filter(({ name, validation }) => {
        const isValid = !validation || validation(currentDraft)

        return visitedStepsName.includes(name) && isValid
      })
    },
    getEmailError: (state) => state.currentEdition.errorMessages.email,
    getPhoneError: (state) => state.currentEdition.errorMessages.phone,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initialState())
    },
    /** @deprecated */
    addEmailError(state, error) {
      state.currentEdition.errorMessages.email = error
    },
    /** @deprecated */
    addPhoneError(state, error) {
      state.currentEdition.errorMessages.phone = error
    },
    /** @deprecated */
    addTransactionId(state, payload) {
      state.currentEdition.transactionId = payload
    },
    /** @deprecated */
    addTransactionHt(state, payload) {
      state.currentEdition.transactionHt = payload
    },
    /** @deprecated */
    addTransactionTax(state, payload) {
      state.currentEdition.transactionTax = payload
    },
    setFlow(state, flow) {
      state.flow = flow
    },
    setPetCountFlow(state, petCountFlow) {
      state.petCountFlow = petCountFlow
    },
    setPetIndex(state, petIndex) {
      state.petIndex = petIndex
    },
    setId(state, id) {
      state.id = id
    },
    setVisitedStepsName(state, stepsName) {
      state.visitedStepsName = stepsName
    },
    setVisitedStepName(state, stepName) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$set(state.visitedStepsName, state.petIndex, [
        ...new Set(state.visitedStepsName[state.petIndex] || []),
        stepName,
      ])
    },
    setCompleted(state) {
      state.isCompleted = true
    },
    setUtm(state, utm) {
      state.utm = utm
    },
  },
  actions: {
    setCompleted({ commit }) {
      commit('setCompleted')
    },
    setUtm({ commit }, utm) {
      commit('setUtm', utm)
    },
    setId({ commit }, id) {
      commit('setId', id)
    },
    setFlow({ commit }, flow) {
      if (!flow) return
      commit('setFlow', flow)
    },
    setPetCountFlow({ commit }, petCountFlow) {
      if (!petCountFlow) return
      commit('setPetCountFlow', petCountFlow)
    },
    setPetIndex({ commit }, petIndex) {
      if (petIndex === null || petIndex === undefined) return
      commit('setPetIndex', petIndex)
    },
    setVisitedStepName({ commit }, stepName) {
      commit('setVisitedStepName', stepName)
    },
    setVisitedStepsName({ commit }, stepsName) {
      commit('setVisitedStepsName', stepsName)
    },
  },
}
