<template>
  <div :class="$style.wrapper">
    <Overlay :opacity="opacity" :class="$style.overlay">
      <TwicImg :src="src" focus="auto" ratio="none" :class="$style.image" />
    </Overlay>

    <div :class="$style.content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import Overlay from './Overlay.vue'

export default defineComponent({
  components: { Overlay },
  props: {
    src: {
      type: String,
      required: true,
    },
    opacity: {
      type: Number,
      default: 0,
    },
  },
})
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  height: 100%;
}
</style>
