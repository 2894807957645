<template>
  <div>
    <Typography :variant="TYPOGRAPHY_TYPES.h4" :class="$style.title" tag="h4">
      {{ recipe.marketLabel }}
    </Typography>

    <Badge v-if="isKibbles" appearance="tertiary" :class="$style.badge">
      <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
        {{ petLabel }}
      </Typography>
    </Badge>

    <TwicImg ratio="16:9" :src="imageUrls.slider[0]" :class="$style.image" />

    <Typography tag="p">
      <RichTranslation
        :translation="{
          value: $i18n(translationKeys.description),
          defaultMessage: `Nos <slug>{label}</slug> sont fabriquées en France et contiennent des ingrédients d'origine naturelle sélectionnés pour leurs bienfaits.`,
        }"
        :values="{ label: recipe.marketLabel }"
      >
        <template #slug="value">
          <span v-if="isKibbles">{{ $i18n('croquettes ') }}</span>

          <span>{{ value }} </span>
        </template>
      </RichTranslation>
    </Typography>

    <Accordeon center :class="$style.accordeon">
      <template #trigger>
        <Typography
          :class="$style.accordeonTrigger"
          :variant="TYPOGRAPHY_TYPES.bodyBold"
        >
          {{ $i18n('Afficher la composition') }}
        </Typography>
      </template>

      <template #content>
        <Divider :class="$style.accordeonDivider" />

        <div :class="$style.compositionWrapper">
          <div :class="$style.composition">
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              {{ $i18n('Informations nutritionnelles') }}
            </Typography>

            <Typography tag="p">
              {{ recipe.formattedComposition.basicIngredients }}
            </Typography>
          </div>

          <div :class="$style.composition">
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              {{ $i18n('Constituants analytiques') }}
            </Typography>

            <Typography tag="p">
              {{ recipe.formattedComposition.analytics }}
            </Typography>
          </div>

          <div
            v-if="
              recipe.formattedComposition.nutritionalAdditives ||
              recipe.formattedComposition.technicalAdditives
            "
            :class="$style.composition"
          >
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              {{ $i18n('Additifs nutritionnels & Energie métabolisable') }}
            </Typography>

            <div v-if="recipe.formattedComposition.nutritionalAdditives">
              <Typography
                :variant="TYPOGRAPHY_TYPES.bodySmallBold"
                tag="div"
                :class="$style.nutritionInformationTitle"
              >
                {{ $i18n('ADDITIFS NUTRITIONNELS (/kg)') }}
              </Typography>

              <Typography>
                {{ recipe.formattedComposition.nutritionalAdditives }}
              </Typography>
            </div>

            <div v-if="recipe.formattedComposition.technicalAdditives">
              <Typography
                :variant="TYPOGRAPHY_TYPES.bodySmallBold"
                tag="div"
                :class="$style.nutritionInformationTitle"
              >
                {{ $i18n('ADDITIFS TECHNOLOGIQUES (/kg)') }}
              </Typography>

              <Typography>
                {{ recipe.formattedComposition.technicalAdditives }}
              </Typography>
            </div>
          </div>
        </div>
      </template>
    </Accordeon>

    <div v-if="!isArrayEmpty(ingredients)">
      <Divider :class="$style.divider" />

      <div :class="$style.ingredients">
        <Ingredient
          v-for="{ title, description, imageUrl } in ingredients"
          :key="title"
          background-color="#f1f3f5"
          :class="$style.ingredient"
          :title="title"
          :description="description"
          :image-url="imageUrl"
        />
      </div>
    </div>

    <div v-if="isKibbles" :class="$style.argumentsWrapper">
      <Divider :class="$style.divider" />

      <Typography :variant="TYPOGRAPHY_TYPES.h5" :class="$style.title" tag="h4">
        {{ $i18n('Les bienfaits de cette recette') }}
      </Typography>

      <Badge appearance="tertiary" :class="$style.badge">
        <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
          {{ petLabel }}
        </Typography>
      </Badge>

      <div :class="$style.arguments">
        <div :class="$style.argument">
          <img src="@front/common/assets/check.svg" />

          <Typography>
            {{ getWording('firstWording') }}
          </Typography>
        </div>

        <div :class="$style.argument">
          <img src="@front/common/assets/check.svg" />

          <Typography>
            {{ getWording('secondWording') }}
          </Typography>
        </div>

        <div :class="$style.argument">
          <img src="@front/common/assets/check.svg" />

          <Typography>
            {{ getWording('thirdWording') }}
          </Typography>
        </div>
      </div>
    </div>

    <Divider :class="$style.divider" />

    <div :class="$style.unusedIngredientsWrapper">
      <Typography
        :variant="TYPOGRAPHY_TYPES.h5"
        :class="[$style.unusedIngredientsTitle, $style.title]"
        tag="h4"
      >
        {{ $i18n('Ce que vous ne retrouverez jamais dans cette recette') }}
      </Typography>

      <Typography tag="p">
        {{
          $i18n(
            "Nous nous interdisons d'utiliser quelconque élément nocif ou indésirable qui n'ont pas leur place dans une alimentation de qualité.",
          )
        }}
      </Typography>

      <div :class="$style.unusedIngredients">
        <div :class="$style.unusedIngredient">
          <img src="@front/common/assets/colorant.svg" />

          <Typography
            :variant="TYPOGRAPHY_TYPES.bodyBold"
            tag="div"
            :class="$style.unusedIngredientText"
          >
            {{
              $i18n({ value: 'unused_ingredients' }, { ingredient: 'colorant' })
            }}
          </Typography>
        </div>

        <div :class="$style.unusedIngredient">
          <img src="@front/common/assets/phosphate_bi_calcique.svg" />

          <Typography
            :variant="TYPOGRAPHY_TYPES.bodyBold"
            tag="div"
            :class="$style.unusedIngredientText"
          >
            {{
              $i18n(
                { value: 'unused_ingredients' },
                { ingredient: 'calcium_carbonate' },
              )
            }}
          </Typography>
        </div>

        <div :class="$style.unusedIngredient">
          <img src="@front/common/assets/indigestible_protein.svg" />

          <Typography
            :variant="TYPOGRAPHY_TYPES.bodyBold"
            tag="div"
            :class="$style.unusedIngredientText"
          >
            {{
              $i18n(
                { value: 'unused_ingredients' },
                { ingredient: 'phosphate_bi_calcique' },
              )
            }}
          </Typography>
        </div>

        <div :class="$style.unusedIngredient">
          <img src="@front/common/assets/emulsifiers.svg" />

          <Typography
            :variant="TYPOGRAPHY_TYPES.bodyBold"
            tag="div"
            :class="$style.unusedIngredientText"
          >
            {{
              $i18n(
                { value: 'unused_ingredients' },
                { ingredient: 'emulsifiers' },
              )
            }}
          </Typography>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getIndredients,
  getPetDescription,
  getPetLabel,
  isArrayEmpty,
} from '@front/common/utils'
import {
  Accordeon,
  Badge,
  Divider,
  Typography,
  TYPOGRAPHY_TYPES,
} from '@front/ui'

import { petWordings } from '../constants'
import { RichTranslation } from '../i18n'

import Ingredient from './Ingredient.vue'

export default {
  components: {
    Typography,
    Accordeon,
    Ingredient,
    Badge,
    Divider,
    RichTranslation,
  },
  props: {
    translationKeys: {
      type: Object,
      required: true,
    },
    pet: {
      type: Object,
      required: true,
    },
    recipe: {
      type: Object,
      required: true,
    },
    imageUrls: {
      type: Object,
      required: true,
    },
    isKibbles: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isArrayEmpty: () => isArrayEmpty,
    TYPOGRAPHY_TYPES: () => TYPOGRAPHY_TYPES,
    petLabel() {
      return getPetLabel(this.pet, this.$i18n)
    },
    ingredients() {
      return getIndredients(this.recipe.ingredients, this.$i18n('ingredients'))
    },
  },
  methods: {
    getWording(wordingType) {
      const description = getPetDescription(this.pet)
      const wordingOptions = petWordings[description]

      return wordingOptions[wordingType]
    },
  },
}
</script>

<style lang="scss" module>
.image {
  height: 241px;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  margin: $ds-spacing-mobile-small 0px;
}

.accordeon {
  margin-top: $ds-spacing-mobile-small;
  padding: $ds-spacing-mobile-small;
  border: 1px solid $ds-color-black;
  border-radius: 8px;
}

.accordeonDivider {
  margin-top: 0px;
}

.compositionWrapper {
  display: flex;
  flex-direction: column;
  gap: $ds-spacing-mobile-small;
}

.composition {
  display: flex;
  flex-direction: column;
  gap: $ds-spacing-mobile-extra-small;
}

.divider {
  width: 100%;
  margin: $ds-spacing-mobile-medium 0px;
}

.ingredients {
  display: flex;
  flex-wrap: wrap;
  gap: $ds-spacing-mobile-small;
}

.ingredient {
  width: calc(50% - ($ds-spacing-mobile-small / 2));
  flex-shrink: 0;
}

.unusedIngredientsTitle {
  text-align: center;
  margin: $ds-spacing-mobile-small 0px;
}

.unusedIngredients {
  display: flex;
  flex-wrap: wrap;
  margin-top: $ds-spacing-mobile-small;
  gap: $ds-spacing-mobile-small;
}

.unusedIngredient {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 calc((100% - 2 * $ds-spacing-mobile-small) / 3);
}

.unusedIngredientText {
  margin-top: $ds-spacing-mobile-extra-small;
  text-align: center;
}

.argumentsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arguments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: $ds-spacing-mobile-small;
  padding: 0px $ds-spacing-mobile-small;
  gap: $ds-spacing-mobile-small;
}

.argument {
  display: flex;
  gap: $ds-spacing-mobile-small;
}

.badge {
  margin-top: $ds-spacing-mobile-extra-small;
}
</style>
