/* eslint-disable no-param-reassign */
import { Pet } from '@front/common/types'

interface Istate {
  pets: Pet[]
}

const initialState = (): Istate => ({
  pets: [],
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    get: (state) => state.pets,
  },
  mutations: {
    set(state, payload: Pet[]) {
      state.pets = payload
    },
  },
}
