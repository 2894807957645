var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:_vm.$style.wrapper},[_c('div',{ref:"triggerRef",class:_vm.$style.triggerWrapper},[_vm._t("trigger")],2),(_vm.shouldShowContent)?_c('Teleport',{attrs:{"to":"body"}},[_c('div',{class:_vm.$style.contentWrapper},[(_vm.withArrow)?_c('div',[_c('svg',{attrs:{"width":"0","height":"0"}},[_c('clipPath',{attrs:{"id":"bottomClipping","clipPathUnits":"objectBoundingBox"}},[_c('path',{attrs:{"d":"M0.001,1 C0.316,1,0.302,0.009,0.501,0.009 C0.7,0.009,0.676,1,1,1 H0.001"}})]),_c('clipPath',{attrs:{"id":"topClipping","clipPathUnits":"objectBoundingBox"}},[_c('path',{attrs:{"d":"M1,0.009 C0.688,0.009,0.701,1,0.503,1 C0.304,1,0.327,0.009,0.002,0.009 L1,0.009"}})]),_c('clipPath',{attrs:{"id":"leftClipping","clipPathUnits":"objectBoundingBox"}},[_c('path',{attrs:{"d":"M0.002,0.002 C0.002,0.316,1,0.303,1,0.501 C1,0.7,0.002,0.677,0.002,1 L0.002,0.002"}})]),_c('clipPath',{attrs:{"id":"rightClipping","clipPathUnits":"objectBoundingBox"}},[_c('path',{attrs:{"d":"M1,1 C1,0.686,0.002,0.699,0.002,0.501 C0.002,0.302,1,0.325,1,0.001 L1,1"}})])]),_c('span',{ref:"arrowRef",class:[
            _vm.$style.arrow,
            ( _obj = {}, _obj[_vm.$style[_vm.calculatedContentPosition.position]] = _vm.calculatedContentPosition.position, _obj ) ],style:(Object.assign({}, _vm.arrowPositionStyle,
            {background: _vm.arrowColor}))})]):_vm._e(),_c('div',{ref:"contentRef",class:[
          ( _obj$1 = {}, _obj$1[_vm.$style.isContentFullWidth] = _vm.isContentFullWidth, _obj$1 ),
          _vm.$style.content ],style:(_vm.contentPositionStyle)},[_vm._t("content")],2)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }