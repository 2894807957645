<template>
  <Modal name="exitFunnel">
    <div :class="$style.modalContent">
      <p :class="$style.modalText">
        {{ $i18n("Retourner à l'accueil et annuler la création du profil ?") }}
      </p>

      <div :class="$style.modalButtonsWrapper">
        <Button
          :class="[$style.modalButtonLeft, $style.modalButton]"
          @click="leaveProfileBuilder"
        >
          {{ $i18n('Oui, quitter') }}
        </Button>

        <Button :class="$style.modalButton" @click="closeModal">
          {{ $i18n('Non, rester') }}
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { Button } from '@front/ui'
import { defineComponent } from '@vue/composition-api'

import Modal from './Modal.vue'

export default defineComponent({
  components: { Button, Modal },
  methods: {
    leaveProfileBuilder() {
      window.location.href = process.env.BASE_URL
      this.closeModal()
    },
    closeModal() {
      this.$store.dispatch('modal/close', 'exitFunnel')
    },
  },
})
</script>

<style lang="scss" module>
.modalContent {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.modalText {
  width: 215px;
  margin-top: 12px;
  margin-bottom: 18px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.modalButtonsWrapper {
  display: flex;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-direction: row;
  }
}

.modalButtonLeft {
  margin-bottom: $ds-spacing-mobile-extra-small;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-right: $ds-spacing-mobile-small;
  }
}

.modalButton {
  width: auto;
  padding: 0.5px 16px 0;
}
</style>
