export default {
  data() {
    return {
      growthBook: null,
    }
  },
  async beforeCreate() {
    if (!this.$initGrowthBook) {
      return
    }

    this.growthBook = await this.$initGrowthBook()
  },
}
