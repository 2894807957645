<template>
  <span>
    <slot :formatted-parts="formattedParts"></slot>
  </span>
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue-demi'

import { intl } from '../plugin'

export default defineComponent({
  name: 'FormattedListParts',
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { list } = toRefs(props)

    const formattedParts = computed(() => {
      return intl.formatListToParts(list.value)
    })

    return {
      formattedParts,
    }
  },
})
</script>
