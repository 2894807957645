/* eslint-disable no-param-reassign */
const initialState = () => ({
  payment: {
    cgv: false,
    errors: {},
    method: 'card',
  },
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    get: (state) => state.payment,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initialState())
    },
    update(state, payment) {
      state.payment = payment
    },
    updateErrorsSubscription(state, { type, message } = {}) {
      if (!type && !message) {
        return // effectively a no-op if error object is incomplete
      }
      state.payment.errors = { type, message }
    },
  },
}
