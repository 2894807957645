import { CAT, DOG } from '@showcase/helpers/constants'

import {
  countStep,
  speciesStep,
  nameStep,
  genderStep,
  spayedStep,
  breedStep,
  ageStep,
  activityStep,
  fatnessStep,
  weightStep,
  repartitionStep,
  parentStep,
  loaderStep,
} from '../steps'

/**
 * @description adds a `restartStep` parameter to `repartition` to add second pet & reset steps
 */

export default {
  [DOG]: [
    countStep,
    speciesStep,
    nameStep,
    genderStep,
    spayedStep,
    breedStep,
    ageStep,
    activityStep,
    fatnessStep,
    weightStep,
    {
      ...repartitionStep,
      restartStep: true,
    },
    loaderStep,
    parentStep,
  ],
  [CAT]: [
    countStep,
    speciesStep,
    nameStep,
    genderStep,
    spayedStep,
    breedStep,
    ageStep,
    activityStep,
    fatnessStep,
    weightStep,
    {
      ...repartitionStep,
      restartStep: true,
    },
    loaderStep,
    parentStep,
  ],
}
