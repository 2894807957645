import { CAT, DOG } from '@showcase/helpers/constants'

import {
  speciesStep,
  nameStep,
  genderStep,
  spayedStep,
  breedStep,
  ageStep,
  activityStep,
  fatnessStep,
  weightStep,
  repartitionStep,
  loaderStep,
} from './steps'

export default {
  [DOG]: [
    speciesStep,
    nameStep,
    genderStep,
    spayedStep,
    breedStep,
    ageStep,
    activityStep,
    fatnessStep,
    weightStep,
    repartitionStep,
    loaderStep,
  ],
  [CAT]: [
    speciesStep,
    nameStep,
    genderStep,
    spayedStep,
    breedStep,
    ageStep,
    activityStep,
    fatnessStep,
    weightStep,
    repartitionStep,
    loaderStep,
  ],
}
