var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Typography',{class:_vm.$style.title,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h4,"tag":"h4"}},[_vm._v("\n    "+_vm._s(_vm.recipe.marketLabel)+"\n  ")]),(_vm.isKibbles)?_c('Badge',{class:_vm.$style.badge,attrs:{"appearance":"tertiary"}},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n      "+_vm._s(_vm.petLabel)+"\n    ")])],1):_vm._e(),_c('TwicImg',{class:_vm.$style.image,attrs:{"ratio":"16:9","src":_vm.imageUrls.slider[0]}}),_c('Typography',{attrs:{"tag":"p"}},[_c('RichTranslation',{attrs:{"translation":{
        value: _vm.$i18n(_vm.translationKeys.description),
        defaultMessage: "Nos <slug>{label}</slug> sont fabriquées en France et contiennent des ingrédients d'origine naturelle sélectionnés pour leurs bienfaits.",
      },"values":{ label: _vm.recipe.marketLabel }},scopedSlots:_vm._u([{key:"slug",fn:function(value){return [(_vm.isKibbles)?_c('span',[_vm._v(_vm._s(_vm.$i18n('croquettes ')))]):_vm._e(),_c('span',[_vm._v(_vm._s(value)+" ")])]}}])})],1),_c('Accordeon',{class:_vm.$style.accordeon,attrs:{"center":""},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('Typography',{class:_vm.$style.accordeonTrigger,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n        "+_vm._s(_vm.$i18n('Afficher la composition'))+"\n      ")])]},proxy:true},{key:"content",fn:function(){return [_c('Divider',{class:_vm.$style.accordeonDivider}),_c('div',{class:_vm.$style.compositionWrapper},[_c('div',{class:_vm.$style.composition},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n            "+_vm._s(_vm.$i18n('Informations nutritionnelles'))+"\n          ")]),_c('Typography',{attrs:{"tag":"p"}},[_vm._v("\n            "+_vm._s(_vm.recipe.formattedComposition.basicIngredients)+"\n          ")])],1),_c('div',{class:_vm.$style.composition},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n            "+_vm._s(_vm.$i18n('Constituants analytiques'))+"\n          ")]),_c('Typography',{attrs:{"tag":"p"}},[_vm._v("\n            "+_vm._s(_vm.recipe.formattedComposition.analytics)+"\n          ")])],1),(
            _vm.recipe.formattedComposition.nutritionalAdditives ||
            _vm.recipe.formattedComposition.technicalAdditives
          )?_c('div',{class:_vm.$style.composition},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n            "+_vm._s(_vm.$i18n('Additifs nutritionnels & Energie métabolisable'))+"\n          ")]),(_vm.recipe.formattedComposition.nutritionalAdditives)?_c('div',[_c('Typography',{class:_vm.$style.nutritionInformationTitle,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallBold,"tag":"div"}},[_vm._v("\n              "+_vm._s(_vm.$i18n('ADDITIFS NUTRITIONNELS (/kg)'))+"\n            ")]),_c('Typography',[_vm._v("\n              "+_vm._s(_vm.recipe.formattedComposition.nutritionalAdditives)+"\n            ")])],1):_vm._e(),(_vm.recipe.formattedComposition.technicalAdditives)?_c('div',[_c('Typography',{class:_vm.$style.nutritionInformationTitle,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallBold,"tag":"div"}},[_vm._v("\n              "+_vm._s(_vm.$i18n('ADDITIFS TECHNOLOGIQUES (/kg)'))+"\n            ")]),_c('Typography',[_vm._v("\n              "+_vm._s(_vm.recipe.formattedComposition.technicalAdditives)+"\n            ")])],1):_vm._e()],1):_vm._e()])]},proxy:true}])}),(!_vm.isArrayEmpty(_vm.ingredients))?_c('div',[_c('Divider',{class:_vm.$style.divider}),_c('div',{class:_vm.$style.ingredients},_vm._l((_vm.ingredients),function(ref){
          var title = ref.title;
          var description = ref.description;
          var imageUrl = ref.imageUrl;
return _c('Ingredient',{key:title,class:_vm.$style.ingredient,attrs:{"background-color":"#f1f3f5","title":title,"description":description,"image-url":imageUrl}})}),1)],1):_vm._e(),(_vm.isKibbles)?_c('div',{class:_vm.$style.argumentsWrapper},[_c('Divider',{class:_vm.$style.divider}),_c('Typography',{class:_vm.$style.title,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h5,"tag":"h4"}},[_vm._v("\n      "+_vm._s(_vm.$i18n('Les bienfaits de cette recette'))+"\n    ")]),_c('Badge',{class:_vm.$style.badge,attrs:{"appearance":"tertiary"}},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n        "+_vm._s(_vm.petLabel)+"\n      ")])],1),_c('div',{class:_vm.$style.arguments},[_c('div',{class:_vm.$style.argument},[_c('img',{attrs:{"src":require("@front/common/assets/check.svg")}}),_c('Typography',[_vm._v("\n          "+_vm._s(_vm.getWording('firstWording'))+"\n        ")])],1),_c('div',{class:_vm.$style.argument},[_c('img',{attrs:{"src":require("@front/common/assets/check.svg")}}),_c('Typography',[_vm._v("\n          "+_vm._s(_vm.getWording('secondWording'))+"\n        ")])],1),_c('div',{class:_vm.$style.argument},[_c('img',{attrs:{"src":require("@front/common/assets/check.svg")}}),_c('Typography',[_vm._v("\n          "+_vm._s(_vm.getWording('thirdWording'))+"\n        ")])],1)])],1):_vm._e(),_c('Divider',{class:_vm.$style.divider}),_c('div',{class:_vm.$style.unusedIngredientsWrapper},[_c('Typography',{class:[_vm.$style.unusedIngredientsTitle, _vm.$style.title],attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h5,"tag":"h4"}},[_vm._v("\n      "+_vm._s(_vm.$i18n('Ce que vous ne retrouverez jamais dans cette recette'))+"\n    ")]),_c('Typography',{attrs:{"tag":"p"}},[_vm._v("\n      "+_vm._s(_vm.$i18n(
          "Nous nous interdisons d'utiliser quelconque élément nocif ou indésirable qui n'ont pas leur place dans une alimentation de qualité."
        ))+"\n    ")]),_c('div',{class:_vm.$style.unusedIngredients},[_c('div',{class:_vm.$style.unusedIngredient},[_c('img',{attrs:{"src":require("@front/common/assets/colorant.svg")}}),_c('Typography',{class:_vm.$style.unusedIngredientText,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold,"tag":"div"}},[_vm._v("\n          "+_vm._s(_vm.$i18n({ value: 'unused_ingredients' }, { ingredient: 'colorant' }))+"\n        ")])],1),_c('div',{class:_vm.$style.unusedIngredient},[_c('img',{attrs:{"src":require("@front/common/assets/phosphate_bi_calcique.svg")}}),_c('Typography',{class:_vm.$style.unusedIngredientText,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold,"tag":"div"}},[_vm._v("\n          "+_vm._s(_vm.$i18n(
              { value: 'unused_ingredients' },
              { ingredient: 'calcium_carbonate' }
            ))+"\n        ")])],1),_c('div',{class:_vm.$style.unusedIngredient},[_c('img',{attrs:{"src":require("@front/common/assets/indigestible_protein.svg")}}),_c('Typography',{class:_vm.$style.unusedIngredientText,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold,"tag":"div"}},[_vm._v("\n          "+_vm._s(_vm.$i18n(
              { value: 'unused_ingredients' },
              { ingredient: 'phosphate_bi_calcique' }
            ))+"\n        ")])],1),_c('div',{class:_vm.$style.unusedIngredient},[_c('img',{attrs:{"src":require("@front/common/assets/emulsifiers.svg")}}),_c('Typography',{class:_vm.$style.unusedIngredientText,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold,"tag":"div"}},[_vm._v("\n          "+_vm._s(_vm.$i18n(
              { value: 'unused_ingredients' },
              { ingredient: 'emulsifiers' }
            ))+"\n        ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }