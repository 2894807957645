// eslint-disable-next-line import/prefer-default-export
export const formatStringWithVariables = (key, variables) => {
  if (!variables) {
    return key
  }

  const regex = /\$(|[a-zA-Z][|\w+]*?)\$/g
  const lowerCaseVariables = Object.entries(variables).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.toLowerCase()]: value,
    }),
    {},
  )

  return key.replace(regex, (...value) => {
    // eslint-disable-next-line no-unused-vars
    const [_, rawValue] = value

    return lowerCaseVariables[rawValue.toLowerCase()]
  })
}
