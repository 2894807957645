/* eslint-disable no-param-reassign */
export default {
  namespaced: true,
  state: () => ({
    products: [],
  }),
  getters: {
    get: (state) => state.products,
  },
  mutations: {
    addProducts(state, payload) {
      state.products = payload
    },
  },
}
