import {
  CustomFormats,
  FormatDateOptions,
  FormatNumberOptions,
  OnErrorFn,
} from '@formatjs/intl'
import {
  formatDate as intlFormatDate,
  formatDateTimeRange as intlFormatDateTimeRange,
} from '@formatjs/intl/src/dateTime'
import countries from 'i18n-iso-countries'
import frCountries from 'i18n-iso-countries/langs/fr.json'
import jwtDecode from 'jwt-decode'
import debounce from 'lodash.debounce'

import {
  ACTIVE,
  ADULT,
  BABY,
  CAT,
  DOG,
  GIFT_FALLBACK_IMG_URL,
  INACTIVE,
  INCOMING_STATE,
  INGREDIENTS_IMG_IDS,
  MONTHLY_PERIOD,
  PET_SIZES,
  STATUSES_STATES,
  UNKNOWN_BREEDS,
  WETFOOD_RATIO,
  WETFOOD_RATIO_VALUES,
} from './constants'
import {
  addToCartDataLayerEvent,
  itemToDalayerProductFactory,
  modifyFromCartDataLayerEvent,
} from './data-layer-events'
import { serviceWithSubscriptionId } from './endpoints'
import { intl, translate } from './i18n/plugin'
import {
  SKIP_PAYMENT_EFFECT,
  OPTION_EFFECT_TYPE,
  EFFECT_SKIP_PAYMENT,
  GIFT_CARD,
  RENEWAL_DISCOUNT_EFFECT,
  DISCOUNT_EFFECT,
  FREE_KIBBLES_EFFECT,
  GODFATHER_PROMOCODE_2_EUROS_EFFECT,
  GODFATHER_PROMOCODE_5_EUROS_EFFECT,
  GODFATHER_PROMOCODE_FREE_DELIVERY_EFFECT,
  VEEPEE_PROMOCODE,
} from './promo-codes'
import {
  Item,
  ItemType,
  namespaceConstantType,
  RecipeFlavor,
  Service,
  ServiceType,
  Subscription,
  Pet,
  PricingType,
  LightDiscount,
  Discount,
  DiscountType,
  Recipe,
  RoleType,
  NextOrder,
  Role,
} from './types'

// Common functions
countries.registerLocale(frCountries)

export function getCountryNameFromCode(code: string, lang = 'fr'): string {
  return countries.getName(code, lang)
}

export function removeDuplicates(array: any[]) {
  return [...new Set(array)]
}

export function centsToEuro(cents: number): number {
  return cents / 100
}

export function euroToCents(cents: number): number {
  return cents * 100
}

export function isArrayEmpty(array = []): boolean {
  if (!Array.isArray(array)) {
    return true
  }

  return Boolean(array.filter(Boolean)?.length === 0)
}

export function isObjectEmpty(object = {}): boolean {
  if (!object) {
    return true
  }

  return Boolean(Object.keys(object).length === 0)
}

export function getPathFromRouteName(routes: any[], name: string): string {
  const { path } =
    routes.find((route) => {
      if (!isArrayEmpty(route.children)) {
        return route.children.find((childrenRoute) => {
          return (
            getPathFromRouteName(route.children, name) === childrenRoute.path
          )
        })
      }

      return route.name === name
    }) || {}

  return path
}

export function getFlagEmoji(countryCode: string): string {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0))

  return String.fromCodePoint(...codePoints)
}

export function weeksToDays(frequency: number): number {
  return Math.floor(frequency * 7)
}

export function daysToWeeks(frequency: number): number {
  return Math.round(frequency / 7)
}

export function daysToMonth(frequency: number): number {
  if (frequency > 60) {
    return 3
  }

  if (frequency > 30) {
    return 2
  }

  return 1
}

export function findScrollableParent(element: HTMLElement): HTMLElement {
  if (!element) {
    return null
  }

  return element.scrollHeight > element.clientHeight
    ? element
    : findScrollableParent(element.parentElement)
}

export function centimersToMinimeters(centimers: number): number {
  return centimers / 10
}

export function capitalize(string = ''): string {
  const [firstLetter, ...rest] = string

  if (!firstLetter) {
    return ''
  }

  return `${firstLetter.toUpperCase()}${rest.join('')}`
}

export function kebabCaseToSentenceCase(string: string): string {
  return capitalize(string).split('_').join(' ')
}

export function sentenceCaseToKebabCase(string: string): string {
  return string
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('-')
}

export function normalize(
  number: number,
  [min, max] = [0, 255],
  [newMin, newMax] = [0, 1],
): number {
  const a = (newMax - newMin) / (max - min)
  const b = newMax - a * max

  return a * number + b
}

export function importDefault(promise: Promise<any>): Promise<any> {
  return promise.then((m) => m.default || m)
}

export function snakeToCamel(string: string): string {
  return string
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    )
}

export function uuid(): string {
  let d = new Date().getTime()

  // d += window.performance.now()

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0

    d = Math.floor(d / 16)

    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function uid(): number {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
}

export function getKibblesDiameterType(
  diameter: number,
  species: string,
): string {
  if (species === CAT) {
    return 'medium'
  }

  if (diameter < 13) {
    return 'small'
  }

  if (diameter < 18) {
    return 'medium'
  }

  return 'big'
}

export function isZeroDate(date) {
  return date.toISOString() === '0001-01-01T00:00:00.000Z' // Go language zerodate cf https://pkg.go.dev/time#Time.IsZero
}

export function isDateValid(date: any): boolean {
  const localDate = new Date(date)

  if (!localDate.getTime || Number.isNaN(localDate.getTime())) {
    return false
  }

  return !isZeroDate(localDate)
}

export function formatDateTimeRange(from: Date, to: Date) {
  return intlFormatDateTimeRange(
    {
      locale: intl.locale,
      onError: intl.onError,
      timeZone: intl.timeZone,
    },
    intl.formatters.getDateTimeFormat,
    new Date(from),
    new Date(to),
    { dateStyle: 'medium' } as any,
  )
}

export function formatDate(
  value: Date | string,
  options: FormatDateOptions = {},
  {
    locale,
    formats,
    onError,
    timeZone,
  }: Partial<{
    locale: string
    timeZone?: string
    formats: CustomFormats
    onError: OnErrorFn
  }> = {},
): string {
  const date = isDateValid(value) ? value : new Date(value)

  if (!isDateValid(date)) {
    return null
  }

  return intlFormatDate(
    {
      locale: locale || intl.locale,
      formats: formats || intl.formats,
      onError: onError || intl.onError,
      timeZone: timeZone || intl.timeZone,
    },
    intl.formatters.getDateTimeFormat,
    date,
    options,
  )
}

export function getShortIsoDate(date: Date): string {
  const [isoDate] = date.toISOString().split('T')

  return isoDate
}

export function deltaDate(
  input: Date,
  { days = 0, months = 0, years = 0 },
): Date {
  return new Date(
    input.getFullYear() + years,
    input.getMonth() + months,
    Math.min(
      input.getDate() + days,
      new Date(
        input.getFullYear() + years,
        input.getMonth() + months + 1,
        0,
      ).getDate(),
    ),
  )
}

export const unixFromAddedTime = ({
  daysToLive = 0,
  hoursToLive = 0,
  minutesToLive = 0,
}) => {
  const MINUTES = 60
  const HOURS = 60 * MINUTES
  const DAYS = 24 * HOURS

  return (
    DAYS * daysToLive +
    ((hoursToLive && HOURS * hoursToLive) || 0) +
    ((minutesToLive && MINUTES * minutesToLive) || 0)
  )
}

export function setLocalStorage(name, value) {
  localStorage.setItem(name, JSON.stringify(value))
}

export function getLocalStorage(name) {
  return JSON.parse(localStorage.getItem(name))
}

export function setCookie(
  name,
  value,
  { daysToLive = 0, hoursToLive = 0, minutesToLive = 0 } = {},
) {
  const date = new Date()
  const expirationTimeInMilliseconds =
    unixFromAddedTime({
      daysToLive,
      hoursToLive,
      minutesToLive,
    }) * 1000

  date.setTime(date.getTime() + expirationTimeInMilliseconds)

  const expires = `expires=${date.toUTCString()}`

  document.cookie = `${name}=${value};${expires};path=/`
}

export const deleteCookie = (name) => {
  setCookie(name, '')
}

export const getCookie = (name) => {
  const cookieArr = document.cookie.split(';')

  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=')

    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1])
    }
  }

  return null
}

export function getValidToken(tokenName: string) {
  try {
    return jwtDecode(getCookie(tokenName)) as any
  } catch {
    return null
  }
}

export const searchMatches = (searchString, string) => {
  const lowerCaseString = string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  const lowerCaseSearchValue = searchString
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  if (!lowerCaseSearchValue) {
    return true
  }

  return lowerCaseString.includes(lowerCaseSearchValue)
}

export function toFixedNumber(
  num: number,
  digits: number,
  base: number,
): number {
  const pow = (base || 10) ** digits

  return Math.round(num * pow) / pow
}

export function hasRoles(
  userRoles: Role[],
  restrictedRoles: RoleType[],
): boolean {
  return restrictedRoles.every((role) => {
    return userRoles.some(({ name }) => {
      return name === role
    })
  })
}

export function repartitionPercentage(
  kibblesMonthlyAmount: number,
  wetfoodMonthlyAmount: number,
): number {
  const wetfoodKibblesEquivalent = 25 // 100g wetfood corresponds to 25g kibbles
  const kibbles = kibblesMonthlyAmount
  const wetfood = wetfoodKibblesEquivalent * wetfoodMonthlyAmount

  return Math.round((wetfood * 100) / (kibbles + wetfood))
}

export function getKibblesRecipeNameFromId(
  recipeId: number,
  recipes: Recipe[],
): string {
  const { article, reference } = recipes.find(({ id }) => id === recipeId) || {}

  return `${article.label} - ${article.id} - ${reference}`
}

export function getProductQuantityFromLabel(label) {
  const quantity = /[0-9]+/g

  return quantity.exec(label)?.[0] || ''
}

export function getUpsellProductTranslationFromId(
  // eslint-disable-next-line default-param-last
  id = 0,
  upsellProductTranslations,
  type,
): string {
  const translation = upsellProductTranslations[id]?.[type]

  if (!translation) {
    throw new Error(`Translation missing for highlight product number ${id}`)
  }

  return translation
}

export function getRecipeFlavor(label = ''): RecipeFlavor {
  if (label.includes('Chicken') || label.includes('Poulet')) {
    return RecipeFlavor.chicken
  }

  if (label.includes('Fish') || label.includes('Poisson')) {
    return RecipeFlavor.fish
  }

  if (label.includes('Beef') || label.includes('Boeuf')) {
    return RecipeFlavor.beef
  }

  return RecipeFlavor.fish
}

export function priceBeforePercentageDiscount(
  price: number,
  percentageDiscount: number,
): number {
  return price / ((100 - percentageDiscount) / 100)
}

export function priceAfterPercentageDiscount(
  price: number,
  percentageDiscount: number,
): number {
  return Math.floor(price * ((100 - percentageDiscount) / 100) * 10) / 10
}

export function getActivePets(pets: Pet[] = []): Pet[] {
  return pets.filter(({ status }) => status === ACTIVE)
}

export function getInactivePets(pets: Pet[] = []): Pet[] {
  return pets.filter(({ status }) => status === INACTIVE)
}

export function roundToTenth(number: number): number {
  return Math.round(number * 10) / 10
}

export function liftToNinety(number: number): number {
  if (number !== 0) {
    return Math.floor(number) + 0.9
  }

  return number
}

export function hasDecimal(number) {
  return number !== Math.floor(number)
}

export function roundOff(num, places) {
  const x = 10 ** places

  return Math.round(num * x) / x
}

export function gramsToKilograms(grams) {
  return grams / 1000
}

export function kilogramsToGrams(grams) {
  return grams * 1000
}

export function getSpeciesEmoji(species = DOG): string {
  return species === DOG ? '🐶' : '🐱'
}

export function shouldSkipPayment(promoCode) {
  if (!promoCode) {
    return false
  }

  const isOldSkipPayment = promoCode.effects?.some((effect) => {
    return effect.type === SKIP_PAYMENT_EFFECT
  })

  const isNewSkipPayment = promoCode.effects?.some((effect) => {
    return (
      effect.type === OPTION_EFFECT_TYPE &&
      effect.data?.includes(EFFECT_SKIP_PAYMENT)
    )
  })

  return isOldSkipPayment || isNewSkipPayment
}

export function isGiftCard(promoCode) {
  if (!promoCode) {
    return false
  }

  const isOldGiftCard = promoCode.promoType === GIFT_CARD
  const isNewGiftCard = promoCode.type === GIFT_CARD

  return isOldGiftCard || isNewGiftCard
}

export function addOrDeleteValueToArray({
  newValue = {},
  comparisonValue = newValue,
  comparisonField = 'id',
  array = [],
} = {}) {
  const withoutSelectedValue = array.filter(
    (item) => (item[comparisonField] || item) !== comparisonValue,
  )
  const isValueAlreadyStored = array.some(
    (item) => (item[comparisonField] || item) === comparisonValue,
  )

  return isValueAlreadyStored
    ? withoutSelectedValue
    : [...withoutSelectedValue, newValue]
}

export function createPromise() {
  let resolver
  let rejecter

  return {
    promise: new Promise<any>((resolve, reject) => {
      resolver = resolve
      rejecter = reject
    }),
    resolver,
    rejecter,
  }
}

export function getIndredients(ingredients = [], ingredientsList = []) {
  return ingredients
    .reduce((acc, ingredientRecipe) => {
      const { description, title } =
        ingredientsList.find(({ type }) => type === ingredientRecipe) || {}
      const imageUrl = INGREDIENTS_IMG_IDS[ingredientRecipe]

      const ingredient = {
        description,
        title,
        imageUrl,
      }

      if (!title || !imageUrl) {
        console.warn(
          `${ingredientRecipe}${title ? '' : ' missing title'}${
            imageUrl ? '' : ' missing image'
          }`,
        )
      }

      if (!title) {
        return acc
      }

      return [...acc, ingredient]
    }, [])
    .slice(0, 4)
}

export const getPublicNamespaceName = (
  constant: namespaceConstantType,
): string => {
  return constant.join('/')
}

export const getPrivateNamespaceName = (
  constant: namespaceConstantType,
): string => {
  return constant[1]
}

export function formatPercentage(number: number): string {
  const priceFormated = number && number.toFixed().toString().replace('.', ',')

  return `${priceFormated}%`
}

export function formatPrice(number: number): string {
  return !number
    ? 'Offert'
    : intl.formatNumber(number, { style: 'currency', currency: 'EUR' })
}

export function getEffectsFromPromocode(promoCode, knownEffects) {
  promoCode?.effects.forEach(({ data, type }) => {
    return (
      (knownEffects[type] && knownEffects[type](data)) ||
      (type === OPTION_EFFECT_TYPE &&
        data.forEach((item) => knownEffects[item] && knownEffects[item](data)))
    )
  })
}

export function isDiscountType(
  discount: Discount,
  type: DiscountType,
): boolean {
  return discount?.type === type
}

export function formatQuantity(
  quantity: number,
  options: FormatNumberOptions = {},
): string {
  return intl.formatNumber(quantity, {
    style: 'unit',
    unit: 'gram',
    unitDisplay: 'narrow',
    notation: 'compact',
    ...options,
  })
}

export function formatDiscountValue(discount: Discount) {
  return `-${
    isDiscountType(discount, DiscountType.percentage)
      ? formatPercentage(discount.amount)
      : formatPrice(centsToEuro(discount.amount))
  }`
}

export function getMultipetDiscountByPetAmount(
  petAmount: number,
  multipetDiscounts = [],
) {
  return (
    multipetDiscounts?.find(({ conditions }) => {
      return conditions.some(({ value }) => value === petAmount)
    }) || {}
  )
}

export function priceBeforeDiscount(price: number, discount: Discount): number {
  if (!discount) {
    return price
  }

  const discountedPrice = isDiscountType(discount, DiscountType.percentage)
    ? priceBeforePercentageDiscount(price, discount.amount)
    : price + centsToEuro(discount.amount)

  return Math.max(0, discountedPrice)
}

export function priceAfterDiscount(price: number, discount: Discount): number {
  if (!discount) {
    return price
  }

  const discountedPrice = isDiscountType(discount, DiscountType.percentage)
    ? priceAfterPercentageDiscount(price, discount.amount)
    : price - centsToEuro(discount.amount)

  return Math.max(0, discountedPrice)
}

// `Items` related
export function isKibblesItem(type: ItemType): boolean {
  return type === ItemType.kibble || type === ItemType.standardisedKibble
}

export function isGoodieItem(type: ItemType): boolean {
  return type === ItemType.goodie
}

export function isWetfoodItem(type: ItemType): boolean {
  return type === ItemType.wetfood
}

export function isCustomFood(type: ItemType): boolean {
  return isKibblesItem(type) || isWetfoodItem(type)
}

export function isUpsellProduct(type: ItemType): boolean {
  return type === ItemType.upsell
}

export function isGift(type: ItemType): boolean {
  return type === ItemType.gift
}

export function getItemsByType(
  items: Item[],
  itemType: ItemType | ItemType[],
): Item[] {
  const itemTypes = Array.isArray(itemType) ? itemType : [itemType]

  return items.filter(({ type }: Item) => {
    if (
      (type === ItemType.kibble || type === ItemType.standardisedKibble) &&
      (itemTypes.includes(ItemType.kibble) ||
        itemTypes.includes(ItemType.standardisedKibble))
    ) {
      return true
    }

    return itemTypes.includes(type)
  })
}

export function excludeItemsByType(
  items: Item[],
  itemType: ItemType | ItemType[],
): Item[] {
  const itemTypes = Array.isArray(itemType) ? itemType : [itemType]

  return items.filter(({ type }) => {
    if (
      (type === ItemType.kibble || type === ItemType.standardisedKibble) &&
      (itemTypes.includes(ItemType.kibble) ||
        itemTypes.includes(ItemType.standardisedKibble))
    ) {
      return false
    }

    return !itemTypes.includes(type)
  })
}

export function getItemByMarketLabel(marketLabel: string, items: Item[]) {
  return items.find(({ recipe }) => {
    return recipe?.marketLabel === marketLabel
  })
}

export function getItemByRecipeId(recipeId: number, items: Item[]) {
  return items.find(({ recipe }) => {
    return recipeId === recipe?.id
  })
}

export function getItemByProductId(
  productId: Item['productId'],
  items: Item[] = [],
) {
  return items?.find((item) => {
    return item.productId === productId
  })
}

export function getItemById(
  items: Item[],
  itemId: number,
): Item | Record<string, never> {
  return (
    items.find(({ id }: Item) => {
      return id === itemId
    }) || {}
  )
}

export function isItemQuantityUpdated(
  { quantity }: Item,
  updatedQuantity: number,
): boolean {
  return quantity.base !== updatedQuantity
}

export function updateItemQuantity(item: Item, updatedQuantity: number): Item {
  return {
    ...item,
    quantity: {
      ...item.quantity,
      base: updatedQuantity,
    },
  }
}

export function getItemsTotalPrice(
  items: Item[],
  field = PricingType.baseWithPromotion,
): number {
  return items.reduce((itemsTotal: number, { pricing }: Item) => {
    return itemsTotal + pricing[field].amount
  }, 0)
}

export function createItem({
  article = {},
  id = uid(),
  type = ItemType.kibble,
  serviceId = 0,
  petId = 0,
  promoCodeId = 0,
  quantity = {
    base: 0,
    daily: 0,
    unit: 'gram',
  },
  pricing = {
    base: {
      amount: 0,
      currency: 'EUR',
    },
    baseWithPromotion: {
      amount: 0,
      currency: 'EUR',
    },
    monthly: {
      amount: 0,
      currency: 'EUR',
    },
  },
  recipe = {},
  imageUrls = {},
  translationKeys = {},
  productId = 0,
}: any = {}): Item {
  return {
    id,
    type,
    recipe,
    petId,
    serviceId,
    isNew: false,
    article,
    productId,
    quantity,
    promoCodeId,
    pricing,
    imageUrls,
    translationKeys,
  }
}

export function createFreeCupItem() {
  return createItem({
    type: ItemType.gift,
    quantity: {
      base: 1,
      daily: 0,
      unit: 'unit',
    },
    imageUrls: {
      legacyTitle: 'Gobelet verseur adapté',
      packshot:
        'https://japhy-assets.twic.pics/download?id=1LjpvjUc_I2KbcjMgN-4tLYh_noKJLriC',
    },
  })
}

export function createUpsellItem({
  id = uid(),
  product,
  quantity = 0,
  serviceId = uid(),
  petId = 0,
}): Item {
  return createItem({
    id,
    type: ItemType.upsell,
    serviceId,
    petId,
    quantity: {
      base: quantity,
      unit: 'gram',
      daily: 0,
    },
    productId: product.id,
    translationKeys: {
      title: `upsell_${product.article.id}_title`,
      description: `upsell_${product.article.id}_description`,
      nutritionInformation: `upsell_${product.article.id}_constituents`,
      constituents: `upsell_${product.article.id}_constituents`,
    },
    imageUrls: {
      packshot: product.imageUrls.packshot,
      thumbnail: product.imageUrls.thumbnail,
      slider: product.imageUrls.slider,
    },
    pricing: {
      base: {
        amount: product.price.amount,
        currency: 'EUR',
      },
      baseWithPromotion: {
        amount: product.price.amount,
        currency: 'EUR',
      },
      monthly: {
        amount: 0,
        currency: 'EUR',
      },
    },
  })
}

export function getFlattenItems(services: Service[] = []): Item[] {
  return services.reduce((acc, { items }: Service) => {
    return [...acc, ...items]
  }, [])
}

export function getItemsByPetId(petIdArg: number, items: Item[]): Item[] {
  return items.filter(({ petId }: Item) => {
    return petIdArg === petId
  })
}

// `Service` related
export function isServiceExisting(
  serviceId: Service['id'],
  services: Service[],
) {
  return services.some(({ id }) => {
    return id === serviceId
  })
}

export function getUpsellExistingId(
  services: Service[],
  service: Service,
): number {
  return services.find(({ items }: Service) => {
    const [{ productId }] = items
    const [{ productId: payloadProductId }] = service.items

    return service.type === ServiceType.upsell && productId === payloadProductId
  })?.id
}

export function getServiceById(
  services: Service[],
  serviceId: number,
): Service {
  return services.find(({ id }) => {
    return id === serviceId
  })
}

export function getServiceByItemId(services: Service[], id: Item['id']) {
  const item = getItemById(getFlattenItems(services), id)

  return getServiceById(services, item.serviceId)
}

export function getServicesTotalPrice(
  services: Service[] = [],
  field = PricingType.base,
): number {
  return services.reduce((servicesTotal: number, { items }: Service) => {
    return servicesTotal + getItemsTotalPrice(items, field)
  }, 0)
}

export function excludeServicesByPetId(
  services: Service[],
  id: Pet['id'],
): Service[] {
  return services.filter(({ petId }: Service) => {
    return petId !== id
  })
}

export function getServicesByPetId(
  services: Service[],
  id: Pet['id'],
): Service[] {
  return services.filter(({ petId }: Service) => {
    return petId === id
  })
}

export function serviceHasWetFood(service: Service): boolean {
  return service?.items?.some(({ type }: Item) => {
    return type === ItemType.wetfood
  })
}

export const getPetServices = (
  services: Service[],
  argPetId: number,
): Service[] => {
  return services.filter(({ petId }) => petId === argPetId)
}

export function getServicesFromActivePets(
  pets: Pet[],
  services: Service[] = [],
): Service[] {
  return getActivePets(pets).reduce((acc, { id }) => {
    return [...acc, ...getPetServices(services, id)]
  }, [])
}

export function getServicesFromInactivePets(
  pets: Pet[],
  services: Service[] = [],
): Service[] {
  return getInactivePets(pets).reduce((acc, { id }) => {
    return [...acc, ...getPetServices(services, id)]
  }, [])
}

export function getServicesByType(
  services: Service[],
  serviceType: ServiceType | ServiceType[],
): Service[] {
  if (typeof serviceType === 'string') {
    return services?.filter(({ type }: Service) => type === serviceType) || []
  }

  return (
    services?.filter(({ type }: Service) => serviceType.includes(type)) || []
  )
}

export function excludeServicesByType(
  services: Service[],
  serviceType: ServiceType,
): Service[] {
  return services.filter(({ type }) => type !== serviceType)
}

export function getUpsellServiceFromProductId(
  services: Service[],
  id: number,
): Service {
  return getServicesByType(services, ServiceType.upsell).find(({ items }) => {
    const [item] = items

    return item.productId === id
  })
}

export const getCustomFoodFromServices = (
  services: Service[] = [],
): Service[] => {
  return services.filter(({ type }: Service) => type === ServiceType.customFood)
}

export function getActiveServices(services: Service[] = []): Service[] {
  return services.filter(({ status }) => status === ACTIVE)
}

export function getInActiveServices(services: Service[] = []): Service[] {
  return services.filter(({ status }) => status === INACTIVE)
}

function bindItemToService(argItem: Item, serviceId): Item {
  return {
    ...argItem,
    serviceId,
  }
}

const GoodieItem: Item = {
  id: null,
  type: ItemType.goodie,
  article: null,
  recipe: null,
  productId: 0,
  quantity: {
    unit: 'gram',
    daily: 0,
    trial: 0,
    monthly: 0,
    base: 1,
  },
  pricing: Object.fromEntries(
    Object.values(PricingType).map((type) => [
      type,
      { amount: 0, currency: 'EUR' },
    ]),
  ),
  imageUrls: null,
  isNew: false,
  translationKeys: null,
}

export function createGoodieService({
  subscriptionId = 0,
  items = [GoodieItem],
  petId = 0,
  serviceId = uid(),
}): Service {
  return {
    type: ServiceType.goodie,
    id: serviceId,
    subscriptionId,
    petId,
    catchphrase: '',
    status: ACTIVE,
    pricing: {},
    items: items.map((itemArg: Item) => bindItemToService(itemArg, serviceId)),
  }
}

export function createUpsellService(
  items: Item[],
  serviceId: number = uid(),
  subscriptionId = 0,
  petId = 0,
  deliveryFrequency = 0,
): Service {
  return {
    type: ServiceType.upsell,
    id: serviceId,
    subscriptionId,
    deliveryFrequency,
    petId,
    catchphrase: '',
    status: ACTIVE,
    pricing: {},
    items: items.map((itemArg: Item) => bindItemToService(itemArg, serviceId)),
  }
}

export function createCustomFoodService(items: Item[], petId: number): Service {
  const serviceId = uid()

  return {
    id: serviceId,
    petId,
    subscriptionId: 0,
    type: ServiceType.customFood,
    catchphrase: '',
    status: '',
    pricing: {},
    items: items.map((itemArg: Item) => bindItemToService(itemArg, serviceId)),
  }
}

export function setItemPrice(
  item: Item,
  amount: number,
  fields = [PricingType.base],
): Item {
  const newPricing = Object.keys(item.pricing).reduce(
    (acc, key: PricingType) => {
      if (!fields.includes(key)) {
        return { ...acc, [key]: item.pricing[key] }
      }

      return {
        ...acc,
        [key]: {
          ...item.pricing[key],
          amount,
        },
      }
    },
    {},
  )

  return {
    ...item,
    pricing: newPricing,
  }
}

export function applyDiscountToServicesByType(
  services: Service[],
  discount: Discount,
  type: ServiceType,
  field = PricingType.base,
): Service[] {
  return services.map((service) => {
    if (service.type !== type) {
      return service
    }

    return {
      ...service,
      items: service.items.map((item) => {
        return setItemPrice(
          item,
          priceAfterDiscount(item.pricing[field].amount, discount),
          [field],
        )
      }),
    }
  })
}

// `Subscription` related

export function findSubscriptionByFrequency(
  subscriptions: Subscription[],
  frequency: number,
): Subscription | false {
  return (
    subscriptions.find(({ deliveryFrequency }: Subscription) => {
      return deliveryFrequency === frequency
    }) || false
  )
}

export function createSubscription(
  services: Service[],
  id = 0,
  shippingFeesType = '',
  customerId = 0,
): Subscription {
  return {
    id,
    customerId,
    paymentMethodId: 0,
    deliveryFrequency: MONTHLY_PERIOD,
    recommendedDeliveryFrequency: 0,
    nextDeliveryDate: '0001-01-06T00:00:00Z',
    nextBillingDate: '0001-01-06T00:00:00Z',
    lastShippingDate: '0001-01-06T00:00:00Z',
    shippingFees: {},
    nextBillingOffset: 0,
    nextDebitDate: '0001-01-06T00:00:00Z',
    isTrial: '',
    isUnpaid: '',
    pricingVersion: 0,
    createdAt: '0001-01-06T00:00:00Z',
    updatedAt: '0001-01-06T00:00:00Z',
    services: services.map((service) => serviceWithSubscriptionId(service, id)),
    shippingFeesType,
    discount: <LightDiscount>{},
    pricing: {},
  }
}

export function getPetsFromSubscription(
  pets: Pet[],
  subscription: Subscription,
): Pet[] {
  return pets.filter((pet) => {
    return !isArrayEmpty(getPetServices(subscription.services, pet.id))
  })
}

export function getPetStatus(petId: Pet['id'], services: Service[]) {
  const [customFood] = getCustomFoodFromServices(
    getPetServices(services, petId),
  )

  return customFood?.status || INACTIVE
}

export function getActivePetsFromSubscription(
  pets: Pet[],
  subscription: Subscription,
): Pet[] {
  return pets.filter((pet) => {
    return !isArrayEmpty(
      getActiveServices(getPetServices(subscription.services, pet.id)),
    )
  })
}

export function setServicesStatus(
  services: Service[],
  status: string,
): Service[] {
  return services.map((service) => {
    return {
      ...service,
      status,
    }
  })
}

export function getActiveMultipetDiscount(
  petsAmount: number,
  discounts: Discount[],
): Discount {
  return (
    discounts?.find(({ conditions }) => {
      return conditions.some(({ value }) => petsAmount >= value)
    }) || null
  )
}

export function deleteItem(payload: Item['id'], items: Item[]) {
  return items.filter(({ id }) => {
    return payload !== id
  })
}

export function deleteService(
  serviceId: Service['id'],
  services: Service[] = [],
) {
  return services.filter(({ id }: Service) => id !== serviceId)
}

export function findItem(item: Item, items: Item[]): Item {
  const petsItems = getItemsByPetId(item.petId, items)
  const itemByType = {
    [ItemType.kibble]: getItemByRecipeId(
      item.recipe?.id,
      getItemsByType(petsItems, ItemType.kibble),
    ),
    [ItemType.standardisedKibble]: getItemByRecipeId(
      item.recipe?.id,
      getItemsByType(petsItems, ItemType.kibble),
    ),
    [ItemType.wetfood]: getItemByRecipeId(
      item.recipe?.id,
      getItemsByType(petsItems, ItemType.wetfood),
    ),
    [ItemType.upsell]: getItemByProductId(item.productId, petsItems),
    [ItemType.goodie]: getItemsByType(items, ItemType.goodie)[0],
  }

  return itemByType[item.type]
}

export function upsertItem(payload: Item, items: Item[]): Item[] {
  const existingItem = findItem(payload, items)

  if (existingItem) {
    const newItems = items.map((item) => {
      return item.id === existingItem.id ? payload : item
    })

    modifyFromCartDataLayerEvent(newItems.map(itemToDalayerProductFactory))

    return newItems
  }

  const newItems = [...items, payload]

  addToCartDataLayerEvent(newItems.map(itemToDalayerProductFactory))

  return newItems
}

export function upsertService(
  payload: Service,
  services: Service[] = [],
): Service[] {
  const [existingUpsellService] =
    payload.type === ServiceType.upsell
      ? getServicesByPetId(
          getServicesByType(services, ServiceType.upsell),
          payload.petId,
        )
      : []
  const existingCustomFoodService = services.find(({ petId }: Service) => {
    return payload.type === ServiceType.customFood && petId === payload.petId
  })

  if (isArrayEmpty(payload.items)) {
    return deleteService(payload.id, services)
  }

  if (existingUpsellService) {
    return services.map((item) => {
      return item.id === existingUpsellService?.id ? payload : item
    })
  }

  if (existingCustomFoodService) {
    return services.map((item) => {
      return item.id === existingCustomFoodService?.id ? payload : item
    })
  }

  return [...services, payload]
}

export function customerHasRelayPoint(customer: any): boolean {
  return (
    Boolean(customer.deliveryContact?.pickupPoint?.externalId) ||
    Boolean(customer.pointRelayAddress?.externalId)
  )
}

export function booleanToYesNo(bool: boolean): string {
  return bool ? 'yes' : 'no'
}

export function yesNoToBoolean(string: string): boolean {
  return string === 'yes'
}

export function truncateString(text: string, length: number): string {
  return text.length > length ? `${text.substring(0, length - 3)}...` : text
}

export function isDiscountInfiniteCountdown({
  countdown,
  initialCountdown,
}: Discount) {
  return countdown === 0 && initialCountdown === 0
}

export function hasRenewalEffects(effects) {
  return effects.some((effect) => {
    const hasInfiniteData =
      Array.isArray(effect.data) &&
      effect.data.some((data) => {
        return isDiscountInfiniteCountdown(data)
      })

    return effect.type === RENEWAL_DISCOUNT_EFFECT && !hasInfiniteData
  })
}

export function hasRenewalDiscount(discounts, discountEffects = []) {
  // Vérifie si postRegistration est vrai
  const hasPostRegistration = discounts.postRegistration

  // Vérifie si il y a des promocodes qui ne sont pas de type RENEWAL_DISCOUNT_EFFECT
  const hasOtherPromoCodes = discountEffects.some(
    (effect) => effect.type !== RENEWAL_DISCOUNT_EFFECT,
  )

  // La bannière s'affiche si postRegistration est vrai ET s'il n'y a pas de promocode qui n'est pas de type RENEWAL_DISCOUNT_EFFECT,
  // OU s'il y a un promocode de type RENEWAL_DISCOUNT_EFFECT
  return (
    (hasPostRegistration && !hasOtherPromoCodes) ||
    hasRenewalEffects(discountEffects) // Vérifie si il y a un promocode avec un effet de type RENEWAL_DISCOUNT_EFFECT
  )
}

export function isDiscountApplicable(discount: Discount) {
  if (!discount) {
    return false
  }

  return (
    (discount.initialCountdown > 0 &&
      discount.countdown > 0 &&
      discount.initialCountdown >= discount.countdown) ||
    isDiscountInfiniteCountdown(discount)
  )
}

export function isDiscountCumulative({ isCumulative }: Discount) {
  return yesNoToBoolean(isCumulative) // FIXME: remove when backend will return boolean
}

export function flattenDiscountsByCountdown(array: Discount[]): Discount[] {
  return (
    array?.reduce((acc, discount) => {
      if (!isDiscountApplicable(discount)) {
        return acc
      }

      if (isDiscountInfiniteCountdown(discount)) {
        return [...acc, discount]
      }

      return [...acc, ...new Array(discount.countdown).fill(discount)]
    }, []) || []
  )
}

export function getDiscountsByType(
  discounts: Discount[],
  discountType: DiscountType,
): Discount[] {
  return discounts.filter(({ type }) => {
    return type === discountType
  })
}

export function totalDiscountsAmount(discounts: Discount[]) {
  const flattenDiscounts = flattenDiscountsByCountdown(discounts)

  return centsToEuro(
    getDiscountsByType(flattenDiscounts, DiscountType.fixedAmount).reduce(
      (total, discount) => {
        return isDiscountApplicable(discount) ? total + discount.amount : total
      },
      0,
    ),
  )
}

export function getNextApplicableDiscounts(discounts: Discount[]): Discount[] {
  let cumulativeNotApplied = false
  let cumulativeApplied = false
  let uncumulativeApplied = false

  return discounts.filter((discount) => {
    if (cumulativeApplied) {
      return false
    }

    if (!isDiscountApplicable(discount) && !isDiscountCumulative(discount)) {
      cumulativeNotApplied = true
      return false
    }

    if (
      isDiscountApplicable(discount) &&
      !isDiscountCumulative(discount) &&
      !uncumulativeApplied
    ) {
      cumulativeApplied = true
      return true
    }

    uncumulativeApplied = true

    return (
      isDiscountApplicable(discount) &&
      (isDiscountCumulative(discount) || cumulativeNotApplied)
    )
  })
}

export function getApplicableDiscounts(discounts: Discount[]) {
  return discounts.filter((discount) => {
    return isDiscountApplicable(discount)
  })
}

export function discountsAmount(discounts: Discount[]) {
  if (!discounts) {
    return 0
  }

  return getDiscountsByType(discounts, DiscountType.fixedAmount).reduce(
    (total, discount) => {
      return total + discount.amount
    },
    0,
  )
}

export function createDiscount(
  amount = 0,
  type = DiscountType.fixedAmount,
): Discount {
  if (!amount) {
    return null
  }

  return {
    amount,
    conditions: null,
    countdown: 1,
    createdAt: '0001-01-01T00:00:00Z',
    customerId: 0,
    id: 0,
    initialCountdown: 1,
    isCumulative: '',
    reason: '',
    target: '',
    type,
    updatedAt: '0001-01-01T00:00:00Z',
  }
}

export function compose(...fns) {
  return function (initialVal) {
    return fns.reduceRight((val, fn) => fn(val), initialVal)
  }
}

export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number,
) {
  const resolveSet = new Set<(p: any) => void>()
  const rejectSet = new Set<(p: any) => void>()

  const debounced = debounce((args: Parameters<F>) => {
    func(...args)
      .then((...res) => {
        resolveSet.forEach((resolve) => resolve(...res))
        resolveSet.clear()
      })
      .catch((...res) => {
        rejectSet.forEach((reject) => reject(...res))
        rejectSet.clear()
      })
  }, wait)

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      resolveSet.add(resolve)
      rejectSet.add(reject)
      debounced(args)
    }) as ReturnType<F>
}

export function getPetSizeFromWeight(weight: number) {
  if (weight >= 5000) {
    return PET_SIZES.large
  }

  if (weight >= 2000) {
    return PET_SIZES.medium
  }

  return PET_SIZES.small
}

export function getPromoGift(promoCode, promos) {
  if (!promoCode) {
    return null
  }

  // Rework this condition when all promocodes use promoType system
  const nonGiftsEffects = [
    VEEPEE_PROMOCODE,
    GODFATHER_PROMOCODE_2_EUROS_EFFECT,
    GODFATHER_PROMOCODE_5_EUROS_EFFECT,
    GODFATHER_PROMOCODE_FREE_DELIVERY_EFFECT,
    SKIP_PAYMENT_EFFECT,
    FREE_KIBBLES_EFFECT,
    DISCOUNT_EFFECT,
    RENEWAL_DISCOUNT_EFFECT,
  ]
  const hasGiftCard = promoCode.promoType === GIFT_CARD
  const skipPayment = shouldSkipPayment(promoCode)

  const shouldAddGift =
    (promoCode.description && promoCode.imageUrl) ||
    // @TODO: remove this ugly condition, backend should send an effect type e.g 'gift_item'
    (!skipPayment &&
      promoCode.effects?.some(({ type }) => !nonGiftsEffects.includes(type)) &&
      !hasGiftCard)

  if (!shouldAddGift) {
    return null
  }

  const { title, img } =
    promos.find(({ slug }) =>
      promoCode.effects?.some(
        ({ type }) => type === slug && !nonGiftsEffects.includes(type),
      ),
    ) || {}
  const description = promoCode.description || title
  const imageUrl = promoCode.imageUrl || img?.url || GIFT_FALLBACK_IMG_URL

  return createItem({
    type: ItemType.gift,
    promoCodeId: promoCode.id,
    translationKeys: {
      title: description,
    },
    quantity: {
      base: 1,
      daily: 0,
      unit: 'unit',
    },
    imageUrls: {
      packshot: imageUrl,
      legacyTitle: description,
    },
  })
}

export function getSubTotalPrice(items: Item[], discount?: Discount): number {
  const customFoodItems = [
    ...getItemsByType(items, ItemType.kibble),
    ...getItemsByType(items, ItemType.wetfood),
  ]

  const upsellItems = getItemsByType(items, ItemType.upsell)
  const upsellPrice = getItemsTotalPrice(upsellItems, PricingType.base)
  const priceType = discount ? PricingType.base : PricingType.baseWithPromotion
  const customFoodPrice = isDiscountType(discount, DiscountType.percentage)
    ? customFoodItems.reduce((total, { pricing }) => {
        return (
          total +
          roundToTenth(priceAfterDiscount(pricing.base.amount, discount))
        )
      }, 0)
    : priceAfterDiscount(
        getItemsTotalPrice(customFoodItems, priceType),
        discount,
      )

  return customFoodPrice + upsellPrice
}

export function getTotalPrice(
  items: Item[],
  discount: Discount,
  shippingFees = 0,
): number {
  return getSubTotalPrice(items, discount) + shippingFees
}

export function defaultTo(value, defaultValue) {
  return isArrayEmpty(value) || isObjectEmpty(value) || !value
    ? defaultValue
    : value
}

export function calculateAgeFromDate(date) {
  const dateDifference = new Date(Date.now() - date.getTime())
  const yearDifference = dateDifference.getUTCFullYear()

  return {
    year: Math.abs(yearDifference - 1970),
    month: dateDifference.getMonth(),
  }
}

export function formatAge(date): string {
  const { year, month } = calculateAgeFromDate(date)

  switch (year) {
    case 0:
      return `${month} mois`

    case 1:
      return '1 an'

    default:
      return `${year} ans`
  }
}

export function formattedPetFields(
  { breeds, weight, activityLevel, corpulenceLevel, isSpayed, gender, birth },
  i18n,
) {
  const corpulenceLevels = {
    1: i18n('Maigre'),
    2: i18n('Idéale'),
    3: i18n('Grassouillet'),
  }
  const activityLevels = {
    1: i18n('Peu actif'),
    2: i18n('Plutôt actif'),
    3: i18n('Très actif'),
  }

  function getBreedsLabel(breeds) {
    const [firstBreed] = breeds || []

    if (UNKNOWN_BREEDS.some((element) => firstBreed.label === element)) {
      return i18n('Race non définie')
    }

    const breedsLabels = breeds.reduce((acc, breed) => {
      return [...acc, i18n(breed.label)]
    }, [])

    return intl.formatList(breedsLabels)
  }

  return {
    breeds: getBreedsLabel(breeds),
    age: formatAge(new Date(birth.birthday)),
    gender: gender === 'male' ? i18n('Mâle') : i18n('Femelle'),
    isSpayed: isSpayed ? i18n('Oui') : i18n('Non'),
    weight: formatQuantity(weight),
    activityLevel: activityLevels[activityLevel],
    corpulenceLevel: corpulenceLevels[corpulenceLevel],
  }
}

export function getPetDescription(pet): string {
  const { species, growthStage, breeds, gender, isSpayed } = pet

  const size = breeds[0].size.name

  switch (species) {
    case 'dog':
      switch (growthStage) {
        case 'baby':
          return size === 'small' ? 'chiot petit race' : 'chiot grande race'

        case 'adult':
          switch (gender) {
            case 'male':
              return isSpayed ? 'chien adulte castré' : 'chien adulte'
            case 'female':
              return isSpayed ? 'chien adulte stérilisé' : 'chien adulte'
            default:
              return ''
          }

        default:
          return 'chien senior 6+'
      }

    case 'cat':
      switch (growthStage) {
        case 'baby':
          return 'chaton'

        case 'adult':
          return isSpayed ? 'chat adulte stérilisé' : 'chat adulte'

        default:
          return 'chat senior 6+'
      }

    default:
      return ''
  }
}

export function getRatioFromValue(value: number): string {
  const ratiosByValues = Object.entries(WETFOOD_RATIO_VALUES).reduce(
    (acc, [key, ratio]) => {
      return { ...acc, [ratio]: key }
    },
    {},
  )

  return ratiosByValues[value]
}

export function getSpayedLabel({ isSpayed, gender }) {
  if (gender === 'male') {
    return isSpayed ? 'castré' : 'pas castré'
  }

  return isSpayed ? 'stérilisée' : 'pas stérilisée'
}

export function getSpeciesLabel({ growthStage, species, count = 1 }) {
  const labels = {
    [BABY]: {
      [DOG]: ['chiot', 'chiots'],
      [CAT]: ['chaton', 'chatons'],
    },
    DEFAULT: {
      [DOG]: ['chien', 'chiens'],
      [CAT]: ['chat', 'chats'],
    },
  }

  const stageLabels = labels[growthStage] || labels.DEFAULT

  return stageLabels[species][count > 1 ? 1 : 0]
}

export function getGrowthLabel({ growthStage }) {
  switch (growthStage) {
    case BABY:
      return ''

    case ADULT:
      return 'adulte'

    default:
      return 'senior'
  }
}

export function getSpeciesAndGrowthLabel(pet) {
  if (getGrowthLabel(pet)) {
    return `${getSpeciesLabel(pet)} ${getGrowthLabel(pet)}`
  }

  return getSpeciesLabel(pet)
}

export function getPetLabel(pet, i18n) {
  const [breed] = pet?.breeds || []
  const breedLabel = breed?.label ? i18n(breed?.label) : ''
  const spayed = pet.isSpayed ? capitalize('stérilisé') : ''

  if (UNKNOWN_BREEDS.some((element) => breed?.label?.includes(element))) {
    if (pet.growthStage === BABY) {
      return `${capitalize(getSpeciesLabel(pet))} ${spayed}`
    }

    return `${capitalize(getSpeciesLabel(pet))} ${capitalize(
      getGrowthLabel(pet),
    )} ${spayed}`
  }

  if (pet.growthStage === BABY) {
    return `${capitalize(getSpeciesLabel(pet))} ${breedLabel} ${spayed}`
  }

  return `${breedLabel} ${capitalize(getGrowthLabel(pet))} ${spayed}`
}

export function createFrequenciesOptions() {
  const [min, max] = [2, 8]

  return Array.from({ length: max - min + 1 }, (_, i) => {
    const frequency = min + i

    return {
      value: frequency,
      label: translate(`{frequency} weeks`, { frequency }),
    }
  })
}

export function updateItemKibbleRecipeId(recipeId: number, item: Item): Item {
  return {
    ...item,
    recipe: {
      ...item.recipe,
      id: recipeId || item.recipe.id,
    },
  }
}

export function updateKibbleRecipeIdFromService(
  recipeId: number,
  service: Service,
) {
  const [kibbles] = getItemsByType(service.items, ItemType.kibble)
  const otherItems = excludeItemsByType(service.items, ItemType.kibble)

  return {
    ...service,
    items: [...otherItems, updateItemKibbleRecipeId(recipeId, kibbles)],
  }
}

export function isStandardised(pets: Pet[]) {
  return (
    pets.length <= 1 &&
    pets.every((pet) => {
      return pet.wetFoodRatio === WETFOOD_RATIO.zero
    })
  )
}

export function booleanStringToBoolean(string: string): boolean {
  return string === 'true'
}

let initialId = 0

export const createPet = ({
  resourceId = uuid(),
  customerId = null,
  birth = null,
  id = initialId,
  breeds = [],
  averageAdultWeight = 0,
  birthdayDay = '',
  name = '',
  diet = '',
  species = '',
  gender = '',
  isSpayed = null,
  weight = 0,
  serviceIds = [],
  weightSources = null,
  growthStage = null,
  activityLevel = 0,
  wetFoodRatio = null,
  corpulenceLevel = 0,
  flags = {},
  status = '',
  pathologies = [],
} = {}): Pet => {
  const pet = {
    birth,
    id,
    breeds,
    resourceId,
    customerId,
    averageAdultWeight,
    birthdayDay,
    name,
    flags,
    growthStage,
    diet,
    species,
    gender,
    isSpayed,
    weight,
    serviceIds,
    weightSources,
    activityLevel,
    corpulenceLevel,
    pathologies,
    status,
    wetFoodRatio,
  }

  initialId += 1

  return pet
}

export function getPetsFromOrder(order, services, pets) {
  const petsIds = new Set()

  order.items.forEach(({ data }) => {
    data.items?.forEach(({ serviceID }) => {
      const { petId } =
        services.find(({ id }) => {
          return id === serviceID
        }) || {}

      if (!petId) {
        return
      }

      petsIds.add(petId)
    })
  })
  return pets.filter((pet) => {
    return [...petsIds].includes(pet.id)
  })
}

export function getItemsByPetIdFromOrder(
  order: NextOrder,
  services: Service[],
): { [itemId: number]: Item[] } {
  return order.items.reduce((acc, { data, article }) => {
    const [item = {}] = data.items || []
    const { quantity, serviceID, recipe, type } = item

    const service = services.find(({ id }) => {
      return id === serviceID
    })
    const petAlreadyHaveItem = acc[service?.petId]?.some((item) => {
      return item.type === type && item.recipe.id === recipe.id
    })

    if (
      !service?.petId ||
      petAlreadyHaveItem ||
      (article.type !== 'goodie' && isGoodieItem(item.type))
    ) {
      return acc
    }

    function getTitleByItemType(type) {
      switch (type) {
        case ItemType.goodie: {
          return 'Goodie'
        }

        case ItemType.upsell: {
          return article.label
        }

        default: {
          return recipe.marketLabel
        }
      }
    }

    return {
      ...acc,
      [service.petId]: [
        ...(acc[service.petId] || []),
        createItem({
          article,
          type,
          petId: service.petId,
          recipe: {
            ...recipe,
            marketLabel: getTitleByItemType(type),
          },
          quantity,
        }),
      ],
    }
  }, {})
}

export function getSelectedItems(
  selectedItemsIds: Item['id'][],
  items: Item[],
): Item[] {
  return selectedItemsIds.map((selectedId) => {
    return items.find(({ id }) => id === selectedId)
  })
}

export function getItemsByModificationType(
  currentItems: Item[],
  newItems: Item[],
) {
  const deletions: Item['id'][] = []
  const additions: Item[] = []
  const updates: Item[] = []

  // handle updates / deletion
  currentItems.forEach((item) => {
    const newItem = findItem(item, newItems)

    if (!isItemQuantityUpdated(item, newItem?.quantity?.base || 0)) {
      return
    }

    if (!newItem) {
      deletions.push(item.id)
      return
    }

    updates.push(newItem)
  })

  // handle addition
  newItems.forEach((item) => {
    if (findItem(item, currentItems)) {
      return
    }

    additions.push(item)
  })

  return { additions, deletions, updates }
}

export function getServicesByModificationType(
  currentServices: Service[],
  newServices: Service[],
) {
  const deletions: Service['id'][] = []
  const additions: Service[] = []
  const updates: Service[] = []

  currentServices.forEach((service) => {
    const newService = newServices.find(({ id }) => {
      return id === service.id
    })

    if (!newService || newService.status === INACTIVE) {
      deletions.push(service.id)
      return
    }

    if (service.status !== newService.status) {
      updates.push(newService)
      return
    }

    const {
      additions: newAddition,
      updates: newUpdates,
      deletions: newDeletions,
    } = getItemsByModificationType(service.items, newService.items)

    if (isArrayEmpty([...newAddition, ...newUpdates, ...newDeletions])) {
      return
    }

    updates.push(newService)
  })

  newServices.forEach((service) => {
    const newService = currentServices.find(({ id }) => {
      return id === service.id
    })

    if (newService) {
      return
    }

    additions.push(service)
  })

  return { additions, deletions, updates }
}

export function getStatusState(status: string): string {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [key] = Object.entries(STATUSES_STATES).find(([_, value]) => {
    return value.includes(status)
  }) || [INCOMING_STATE]

  return key
}

export function isBusinessDay(date: Date) {
  const dayOfWeek = date.getDay()

  return dayOfWeek !== 0
}

export function addBusinessDays(startDate: Date, days: number): Date {
  const date = new Date(startDate)

  return [...new Array(days)].reduce((acc) => {
    do {
      acc.setDate(acc.getDate() + 1)
    } while (!isBusinessDay(acc))

    return acc
  }, date)
}

export function sortAlphabetically(a, b) {
  const valueA = a.toUpperCase()
  const valueB = b.toUpperCase()

  if (valueA < valueB) {
    return -1
  }

  if (valueA > valueB) {
    return 1
  }

  return 0
}
