/* eslint-disable global-require */
import { isVue2 } from 'vue-demi'

// eslint-disable-next-line import/no-mutable-exports
let Vuex = null

if (isVue2) {
  Vuex = require('@vueblocks/vue-use-vuex')
} else {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  Vuex = require('../../../apps/back-office/node_modules/vuex')
}

export default Vuex
