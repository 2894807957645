<template>
  <div :class="$style.wrapper">
    <div :class="$style.pinnedContent">
      <slot />
    </div>

    <Typography
      v-if="text"
      :class="$style.text"
      :variant="TYPOGRAPHY_TYPES.handwritten"
    >
      {{ text }}
    </Typography>

    <TwicImg :src="src" :class="$style.image" :ratio="ratio" />
  </div>
</template>

<script>
import { Typography, TYPOGRAPHY_TYPES } from '.'

export default {
  components: { Typography },
  props: {
    src: {
      type: String,
      required: true,
    },
    ratio: {
      type: [Number, String],
      default: '1',
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    text: {
      type: String,
      default: null,
    },
  },
  computed: {
    TYPOGRAPHY_TYPES: () => TYPOGRAPHY_TYPES,
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  border-radius: 18px;
  overflow: hidden;
  position: relative;
  container-type: size;
}

.image {
  flex-shrink: 0;
  width: 100%;
}

.text.text {
  position: absolute;
  top: calc(50% + 2%);
  left: 50%;
  transform: translate(-50%, -50%);
  color: #3e3e3e;
  font-size: 10cqw;
  text-align: center;
  text-transform: capitalize;
  z-index: 2;
}

.pinnedContent {
  position: absolute;
  top: $ds-spacing-mobile-extra-small;
  left: $ds-spacing-mobile-extra-small;
}
</style>
