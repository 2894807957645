import { onBeforeMount, onBeforeUpdate, ref } from 'vue-demi'

export default function isMobile() {
  const isMobile = ref(true)

  function updateMobileStatus() {
    const mediaQuery = window.matchMedia('only screen and (min-width: 1112px)')

    isMobile.value = !mediaQuery.matches
  }

  onBeforeMount(() => {
    updateMobileStatus()

    window.addEventListener('resize', updateMobileStatus)
  })

  onBeforeUpdate(() => updateMobileStatus)

  return isMobile
}
