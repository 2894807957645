/* eslint-disable no-param-reassign */
import { postTrialSubscriptionSimulation } from '@front/common/endpoints'
import { Subscription } from '@front/common/types'
import { booleanToYesNo, customerHasRelayPoint } from '@front/common/utils'

interface Istate {
  trial: Subscription | null
}

const initialState = (): Istate => ({
  trial: null,
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    get: (state) => state.trial,
  },
  mutations: {
    set(state, payload: Subscription | null) {
      state.trial = payload
    },
  },
  actions: {
    async fetch({ commit, rootGetters }, payload: any) {
      const customer = rootGetters['customer/get']
      const promoCode = rootGetters['cart/getPromoCode']

      const fetchedSubscription = await postTrialSubscriptionSimulation(
        payload,
        promoCode?.code,
        customer.country,
        booleanToYesNo(customerHasRelayPoint(customer)),
        true,
      )

      commit('set', fetchedSubscription)
    },
  },
}
