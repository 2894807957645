export const CAT = 'cat'
export const DOG = 'dog'

export const TRIAL = 'trial'
export const MONTHLY = 'monthly'

export const MIXED_DIET = 'mixed'
export const KIBBLE_DIET = 'kibble'

export const KIBBLES = 'kibble'
export const WETFOOD = 'wetFood'

export const CHICKEN = 'Chicken'
export const FISH = 'Fish'

export const CUSTOM_FOOD = 'custom_food'
export const UPSELL = 'upsell'

export const DOG_FISH_IMAGE_ID =
  'https://japhy-assets.twic.pics/download?id=1E4BPmdpieI_cBYMl4UXjl3BMrUGfoKzi'
export const DOG_CHICKEN_IMAGE_ID =
  'mabrouk/img/profile-builder/dog-chicken-pack_hmcoku.png'
export const CAT_FISH_IMAGE_ID =
  'https://japhy-assets.twic.pics/download?id=1E5zHpazh4D14TFOEqQU5dEljGXNycBHU'
export const CAT_CHICKEN_IMAGE_ID =
  'mabrouk/img/profile-builder/cat-chicken-pack_mmrts0.png'
export const CAT_WETFOOD_IMAGE_ID =
  'mabrouk/img/profile-builder/cat-fish-wetFood_mbnkdy.png'
export const DOG_WETFOOD_IMAGE_ID =
  'mabrouk/img/profile-builder/Conserve_chien_transparent_ombre_rcc3tw.png'

export const CARD = 'card'
export const PAYPAL = 'paypal'
export const STRIPE = 'stripe'

export const ACTIVE = 'active'

export const TWO_PETS_OFFER_DISCOUNT_PERCENTAGE = '-20%'
export const MULTI_PETS_OFFER_DISCOUNT_PERCENTAGE = '-30%'
export const TRIAL_OFFER_DISCOUNT_PERCENTAGE = '-50%'
