import Vuex from '@front/common/vuex'

export default () => {
  const store = Vuex.useStore()
  const { useGetters } = Vuex.useVuex()

  function handleOpenAlert(options) {
    store.dispatch('alert/open', options)
  }
  function closeAlert() {
    store.dispatch('alert/close')
  }

  function HOCtriggerAlert(fn, options) {
    return async (...args) => {
      await fn(...args)

      handleOpenAlert(options)
    }
  }

  return {
    ...useGetters({
      isOpen: 'alert/getVisibility',
      status: 'alert/getStatus',
      message: 'alert/getMessage',
      delay: 'alert/getDelay',
    }),
    triggerAlert: (options) => handleOpenAlert(options),
    HOCtriggerAlert,
    closeAlert,
  }
}
