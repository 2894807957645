export default {
  data() {
    return {
      isMobile: true,
    }
  },
  beforeMount() {
    this.updateMobileStatus()

    window.addEventListener('resize', this.updateMobileStatus)
  },
  beforeUpdate() {
    this.updateMobileStatus()
  },
  methods: {
    updateMobileStatus() {
      const mediaQuery = window.matchMedia(
        'only screen and (min-width: 1112px)',
      )

      this.isMobile = !mediaQuery.matches
    },
  },
}
