import { h } from 'vue-demi'

import { isObjectEmpty } from '@front/common/utils'

import { translate } from '../plugin'

export default {
  props: {
    translation: {
      type: [Object, String],
      required: true,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    // @TODO: Find a way to avoid vue3 warnings
    i18n() {
      return translate
    },
    slots() {
      return !isObjectEmpty(this.$slots) ? this.$slots : this.$scopedSlots
    },
  },
  render(createElement) {
    const slots = Object.entries(this.slots).reduce(
      (acc, [key, scopedSlot]) => {
        const isVueAttribute = key.includes('$')

        return isVueAttribute
          ? acc
          : {
              ...acc,
              [key]: (value = []) => scopedSlot && scopedSlot(...value),
            }
      },
      {},
    )

    const customCreateElement =
      typeof createElement === 'function' ? createElement : h

    return customCreateElement(
      this.tag,
      this.i18n(this.translation, { ...slots, ...this.values }),
    )
  },
}
