import { SINGLE_PET, TWO_PETS, INFINITE_PETS } from '../constants'

import infinitePetsSteps from './infinitePets.steps'
import singlePetSteps from './singlePet.steps'
import twoPetsSteps from './twoPets.steps'

export default {
  [SINGLE_PET]: singlePetSteps,
  [TWO_PETS]: twoPetsSteps,
  [INFINITE_PETS]: infinitePetsSteps,
}
