var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.kibblesWrapper},[_c('KibblesSelector',{class:_vm.$style.kibblesSelector,attrs:{"pet":_vm.pet,"items":_vm.items,"selected-items-ids":_vm.selectedKibblesIds},on:{"selected":function (data) { return _vm.$emit('recipeSelection', data); }}}),_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyLarge}},[_vm._v("\n    "+_vm._s(_vm.$i18n('Choisir sa contenance'))+"\n  ")]),(!_vm.isArrayEmpty(_vm.frequenciesServices))?_c('Slider',{class:_vm.$style.slider,attrs:{"options":{
      perView: 3,
      startAt: _vm.selectedIndex,
      breakpoints: {
        1112: {
          perView: 1,
        },
      },
    }}},_vm._l((_vm.frequenciesServices),function(service){return _c('PackSizeBlock',{key:service.id,attrs:{"service":service,"is-selected":_vm.selectedServiceId === service.id},nativeOn:{"click":function($event){return (function () { return _vm.$emit('frequencySelection', service); }).apply(null, arguments)}}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }