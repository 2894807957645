import { petSchema, Pet } from '@front/common/types'
import { importDefault } from '@front/common/utils'
import Vue from 'vue'
import { reach } from 'yup'

import {
  SHOWCASE_ROUTE_PROFILE_BUILDER_COUNT,
  SHOWCASE_ROUTE_PROFILE_BUILDER_SPECIES,
  SHOWCASE_ROUTE_PROFILE_BUILDER_NAME,
  SHOWCASE_ROUTE_PROFILE_BUILDER_GENDER,
  SHOWCASE_ROUTE_PROFILE_BUILDER_SPAYED,
  SHOWCASE_ROUTE_PROFILE_BUILDER_BREED,
  SHOWCASE_ROUTE_PROFILE_BUILDER_AGE,
  SHOWCASE_ROUTE_PROFILE_BUILDER_ACTIVITY,
  SHOWCASE_ROUTE_PROFILE_BUILDER_FATNESS,
  SHOWCASE_ROUTE_PROFILE_BUILDER_WEIGHT,
  SHOWCASE_ROUTE_PROFILE_BUILDER_REPARTITION,
  SHOWCASE_ROUTE_PROFILE_BUILDER_VALIDATE,
  SHOWCASE_ROUTE_PROFILE_BUILDER_PARENT,
  SHOWCASE_ROUTE_PROFILE_BUILDER_LOADER,
} from '@showcase/core/routes-names'

export const countStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_COUNT,
}

export const speciesStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_SPECIES,
  validation(pet: Pet) {
    return reach(petSchema, 'species').isValidSync(pet.species)
  },
}
export const nameStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_NAME,
  component: () => importDefault(import('../pages/Name')),
  validation(pet: Pet) {
    return reach(petSchema, 'name').isValidSync(pet.name)
  },
}
export const genderStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_GENDER,
  validation(pet: Pet) {
    return reach(petSchema, 'gender').isValidSync(pet.gender)
  },
}
export const spayedStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_SPAYED,
  validation(pet: Pet) {
    return reach(petSchema, 'isSpayed').isValidSync(pet.isSpayed)
  },
}
export const breedStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_BREED,
  validation(pet: Pet) {
    return reach(petSchema, 'breeds').isValidSync(pet.breeds)
  },
}
export const ageStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_AGE,
  validation(pet: Pet) {
    return reach(petSchema, 'birth').isValidSync(pet.birth)
  },
}
export const activityStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_ACTIVITY,
  validation(pet: Pet) {
    return reach(petSchema, 'activityLevel').isValidSync(pet.activityLevel)
  },
}
export const fatnessStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_FATNESS,
  validation(pet: Pet) {
    return reach(petSchema, 'corpulenceLevel').isValidSync(pet.corpulenceLevel)
  },
}
export const weightStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_WEIGHT,
  validation(pet: Pet) {
    return reach(petSchema, 'weight').isValidSync(pet.weight)
  },
}
export const repartitionStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_REPARTITION,
  validation(pet: Pet) {
    return reach(petSchema, 'diet').isValidSync(pet.diet)
  },
}

export const validateStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_VALIDATE,
}

export const parentStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_PARENT,
  validation(pet: Pet) {
    return petSchema.isValidSync(pet)
  },
  isDisplayed() {
    return Vue.prototype.$growthBook?.isOn('parent-step')
  },
}

export const loaderStep = {
  name: SHOWCASE_ROUTE_PROFILE_BUILDER_LOADER,
  validation(pet: Pet) {
    return petSchema.isValidSync(pet)
  },
  isDisplayed() {
    return Vue.prototype.$growthBook?.isOn('loader-step')
  },
}
