<template>
  <label :class="$style.wrapper">
    <input v-model="internalValue" :class="$style.checkbox" type="checkbox" />

    <div :class="$style.switch" />
  </label>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
    }
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal
    },
    internalValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', newVal)
      }
    },
  },
}
</script>

<style lang="scss" module>
$width: 44px;
$height: 24px;
$offset: 4px;
$horizontalOffset: calc($offset / 2);
$roundSize: $height - $offset;

.wrapper {
  display: inline-block;

  cursor: pointer;
}

.switch {
  position: relative;

  display: inline-block;
  width: $width;
  height: $height;
  border-radius: 16px;

  background: $ds-color-black-borders;
  vertical-align: middle;
  transition: background 0.25s;
}

.switch:before,
.switch:after {
  content: '';
}

.switch:before {
  position: absolute;
  top: 50%;
  left: $horizontalOffset;

  display: block;
  width: $roundSize;
  height: $roundSize;
  border-radius: 50%;

  transform: translateY(-50%);
  background: white;
  transition: left 0.25s;
}

.toggle:hover .switch:before {
  background: white;
  box-shadow: 0 0 0 1px $ds-color-black-label;
}

.checkbox:checked + .switch {
  background: $ds-color-blue-dark;
}

.checkbox:checked + .switch:before {
  left: calc(#{$width} - #{$roundSize} - #{$horizontalOffset});
}

.checkbox {
  position: absolute;

  visibility: hidden;
}
</style>
