import Vue from 'vue'
import Router from 'vue-router'

import routes from './routes'

Vue.use(Router)

// eslint-disable-next-line import/prefer-default-export
export function createRouter() {
  return new Router({
    mode: 'history',
    base: '/',
    routes,
    scrollBehavior: () => ({ x: 0, y: 0 }),
  })
}
