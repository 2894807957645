var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('img',{class:_vm.$style.flashIcon,attrs:{"src":require("@front/common/assets/flash.svg")}}),_c('Typography',{class:_vm.$style.dailyCalories,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallBold}},[(_vm.pet)?_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h3}},[_vm._v("\n      "+_vm._s(_vm.pet.dailyEnergyRequirement)+"\n    ")]):_vm._e(),_vm._v("\n    kcal\n\n    "),_c('div',[_vm._v(_vm._s(_vm.$i18n('par jour')))])],1),_c('Typography',{class:_vm.$style.quantityDescription,attrs:{"tag":"p"}},[_c('span',[_vm._v("\n      "+_vm._s(_vm.$i18n(
          'Notre algorithme vétérinaire recommande {kibblesQuantity} de croquettes par jour pour {petName}',
          {
            kibblesQuantity: _vm.formatQuantity(_vm.dailyKibblesQuantity),
            petName: _vm.pet.name,
          }
        ))+"\n    ")]),(_vm.dailyWetfoodQuantity)?_c('span',[_vm._v("\n      "+_vm._s(_vm.$i18n('et {wetfoodQuantity} de terrine', {
          wetfoodQuantity: _vm.formatQuantity(_vm.dailyWetfoodQuantity),
        }))+"\n    ")]):_vm._e()]),(_vm.dailyWetfoodQuantity && _vm.kibblesPercentage)?_c('img',{class:_vm.$style.infoIcon,attrs:{"src":require("@front/common/assets/info_icon.svg")},on:{"click":_vm.openModal}}):_vm._e(),(_vm.kibblesPercentage)?_c('NeedsModal',{attrs:{"wetfood-percentage":_vm.wetfoodPercentage,"kibbles-percentage":_vm.kibblesPercentage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }